import { Contact, SpiderTxtState } from '../types';
import { FullState } from '../../store';
import { createSelector } from 'reselect';

export const getSpiderTxt = (state: FullState): SpiderTxtState => state.spiderTxt;

export const getActiveContactId = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): string | undefined => spiderTxt.activeContactId
);

export const getContactMap = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): Map<string, Contact> => spiderTxt.contactMap
);
