import React from 'react';

import './styles/main.scss';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  MessageSeparator,
  ConversationHeader,
  ConversationList,
  Conversation,
  Search,
  Sidebar,
  InputToolbox
} from '@chatscope/chat-ui-kit-react';
import ContactList from './contactList';

const SpiderTxtPage = () => {
  if (window.env.ENV !== 'local') {
    console.log('SpiderTxtPage not local');
    return <></>;
  }
  return (
    <MainContainer>
      <Sidebar
        loading={false} // set to true when loading contacts intialy
        position="left"
      >
        <Search
          onChange={e => {
            console.log(`search onChange ${e}`);
          }}
          placeholder="Search..."
        />

        <ContactList />
      </Sidebar>

      <ChatContainer
        style={
          {
            //   height: '500px'
          }
        }
      >
        <ConversationHeader>
          <Avatar
            name="Emily"
            src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
          />
          <ConversationHeader.Content info="Active 10 mins ago" userName="Emily" />
        </ConversationHeader>
        <MessageList
          loading={false} // set when loading messages intially
          loadingMore={false} // set to true when loading more messages
          onYReachStart={() => {
            console.log('onYReachStart');
          }}
          onYReachEnd={() => {
            console.log('onYReachEnd');
          }}
        >
          <MessageSeparator>Saturday, 30 November 2019</MessageSeparator>
          <Message
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'single',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          >
            <Message.Header sender="Emily" sentTime="15 mins ago" />
            <Avatar
              name="Emily"
              src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
            />
          </Message>
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'single',
              sender: 'Oliver',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'first',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <MessageSeparator as={'h2'}>System message !</MessageSeparator>

          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'normal',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'normal',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'last',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          >
            <Avatar
              name="Emily"
              src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
            />
          </Message>
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'first',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'normal',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'normal',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'last',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'first',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'last',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          >
            <Avatar
              name="Emily"
              src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
            />
          </Message>
          <MessageSeparator>Saturday, 31 November 2019</MessageSeparator>
          <Message
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'single',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          >
            <Avatar
              name="Emily"
              src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
            />
          </Message>
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'single',
              sender: 'Oliver',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'first',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'normal',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'normal',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'last',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          >
            <Message.Footer sender="Emily" sentTime="15 mins ago" />

            <Avatar
              name="Emily"
              src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
            />
          </Message>
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'first',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'normal',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'normal',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'outgoing',
              message: 'Hello my friend',
              position: 'last',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            avatarSpacer
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'first',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          />
          <Message
            model={{
              direction: 'incoming',
              message: 'Hello my friend',
              position: 'last',
              sender: 'Emily',
              sentTime: '15 mins ago'
            }}
          >
            <Avatar
              name="Emily"
              src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
            />
          </Message>
        </MessageList>

        <InputToolbox>Charachters left: 200</InputToolbox>
        <MessageInput
          onSend={(innerHtml, textContent, innerText, nodes) => {
            alert(`Message sent ${textContent}`);
          }}
          onChange={(innerHtml, textContent, innerText, nodes) => {
            console.log(textContent);
          }}
          autoFocus={true}
          attachButton={false}
          placeholder="Type message here"
        />
      </ChatContainer>
    </MainContainer>
  );
};

export default SpiderTxtPage;
