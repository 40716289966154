import React, { useState } from 'react';
import { Drawer, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { ValidationRule, WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';
import { ConfirmationButtons } from '../../../common/form';

interface ComponentProps extends FormComponentProps {
  form: WrappedFormUtils;
  onClose: () => void;
  onCreate: (emailAddresses: string, message?: string) => void;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const InviteUsersDrawer: React.FC<ComponentProps> = ({ onClose, onCreate, form }) => {
  const [inviteButtonDisabled, setInviteButtonDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { getFieldDecorator } = form;

  const formSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    form.validateFields();
    const emailAddresses = form.getFieldValue('emailAddresses');
    const customMessage = form.getFieldValue('customMessage');
    onCreate(emailAddresses, customMessage);
    form.resetFields();
  };

  const validateEmails = (
    _rule: ValidationRule,
    value: string,
    callback: (message?: string) => {}
  ) => {
    if (value) {
      const emails = value.split(',');
      const invalidEmail = emails.find((email: string) => !EMAIL_REGEX.test(email.trim()));
      if (invalidEmail) {
        callback(`Email: ${invalidEmail} is not valid`);
      }
    }
    callback();
  };

  return (
    <Drawer title="Invite" placement={'right'} visible={true} width={400} onClose={onClose}>
      <Form
        layout="vertical"
        onChange={() => {
          setInviteButtonDisabled(
            form.getFieldValue('emailAddresses') === '' ||
              form.getFieldError('emailAddresses') !== undefined
          );
        }}
        onSubmit={formSubmitHandler}
      >
        <Form.Item
          label="Email addresses (comma separated):"
          style={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {getFieldDecorator('emailAddresses', {
            initialValue: '',
            validateFirst: true,
            rules: [
              { required: true, message: 'Please enter at least one email address' },
              { validator: validateEmails }
            ]
          })(<Input.TextArea rows={4} disabled={isSaving} />)}
        </Form.Item>
        <Form.Item
          label={'Custom message (optional):'}
          style={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {getFieldDecorator('customMessage', {
            initialValue: ''
          })(<Input.TextArea rows={4} disabled={isSaving} />)}
        </Form.Item>
        <ConfirmationButtons
          onClose={onClose}
          isSaving={isSaving}
          disabled={inviteButtonDisabled}
          text={'Invite'}
        />
      </Form>
    </Drawer>
  );
};

InviteUsersDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired
};

export default Form.create<ComponentProps>()(InviteUsersDrawer);
