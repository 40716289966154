import { useSelector } from 'react-redux';
import { getActiveKmls, getKmlData } from '../../../redux/selectors/mapData';
import { useEffect, useState } from 'react';

/**
 * Asserts that a PromiseSettledResult represents a fulfilled promise.
 *
 * @param result - The PromiseSettledResult to check
 * @throws {Error} When the promise was rejected instead of fulfilled
 *
 * @remarks
 * This is a type guard function that narrows the type of the result parameter
 * to PromiseFulfilledResult<T> when the assertion passes.
 */
function assertPromiseFulfilledResult<T>(
  result: PromiseSettledResult<T>
): asserts result is PromiseFulfilledResult<T> {
  if (result.status === 'rejected') {
    throw new Error('Promise was rejected, but expecting fulfilled');
  }
}

/**
 * Asserts that a PromiseSettledResult represents a rejected promise.
 *
 * @param result - The PromiseSettledResult to check
 * @throws Error if the promise was fulfilled instead of rejected
 *
 * @example
 * ```typescript
 * const results = await Promise.allSettled([promise1, promise2]);
 * assertPromiseRejectedResult(results[0]); // Throws if results[0] was fulfilled
 * console.log(results[0].reason); // Safe to access .reason after assertion
 * ```
 */
function assertPromiseRejectedResult<T>(
  result: PromiseSettledResult<T>
): asserts result is PromiseRejectedResult {
  if (result.status === 'fulfilled') {
    throw new Error('Promise was accepted, but expecting rejected');
  }
}

export const useActiveKmls = (): { layers: string[]; failedLayers: string[] } => {
  const [layers, setLayers] = useState<string[]>([]);
  const [failedLayers, setFailedLayers] = useState<string[]>([]);

  const kmlData = useSelector(getKmlData);
  const activeKmls = useSelector(getActiveKmls);

  useEffect(() => {
    const loadKMLs = async () => {
      const promises = await Promise.allSettled(
        kmlData
          .filter(kml => activeKmls.includes(kml.id))
          .filter(kml => kml.id !== 'geofence')
          .map(async kml => {
            const url =
              kml.url && kml.url.length
                ? kml.url
                : `${window.env.STL_GO_ORIGIN}/kmlResource/${kml.id}`;
            try {
              const response = await fetch(url);
              if (!response.ok) {
                throw kml;
              } else {
                const blob = await response.blob();
                return await blob.text();
              }
            } catch {
              throw kml;
            }
          })
      );
      setLayers(
        promises
          .filter(p => p.status === 'fulfilled')
          .map(p => {
            assertPromiseFulfilledResult(p);
            return p.value;
          })
      );
      setFailedLayers(
        promises
          .filter(p => p.status === 'rejected')
          .map(p => {
            assertPromiseRejectedResult(p);
            return p.reason.name;
          })
      );
    };
    loadKMLs();
  }, [activeKmls]);

  return { layers, failedLayers };
};
