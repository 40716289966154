import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

const { confirm } = Modal;

interface NotificationDrawerProps extends FormComponentProps {
  notificationConfigurationId: string;
  deleteNotification: (notificationConfigurationId: string) => Promise<void>;
  onClose: () => void;
}

export const showDeleteNotificationConfigurationModal = ({
  notificationConfigurationId,
  deleteNotification,
  onClose
}: NotificationDrawerProps) =>
  confirm({
    title: 'Delete Notification Configuration',
    icon: <ExclamationCircleFilled />,
    width: '40%',
    content: (
      <>
        <p>This change will affect all users in the Notification Configuration.</p>
        <p>
          To remove individual users (including yourself), please Edit the Notification
          Configuration and update the People list instead.
        </p>
      </>
    ),
    okText: 'Delete',
    okButtonProps: { type: 'danger' },
    onOk() {
      deleteNotification(notificationConfigurationId);
      onClose();
    }
  });

showDeleteNotificationConfigurationModal.propTypes = {
  notificationConfigurationId: PropTypes.string.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
export default showDeleteNotificationConfigurationModal;
