import { SeriesOptionsType } from 'highcharts';
import Highcharts from 'highcharts/highmaps';
import { kml } from '@tmcw/togeojson';
import { Geojson } from '../../../common/api/spidertracks-sdk/types/GeoJSON';

/**
 * Converts KML (Keyhole Markup Language) text to Highcharts map series options.
 *
 * @param kmlText - The KML document content as a string
 * @returns An array of map series configuration objects compatible with Highcharts
 *
 * @remarks
 * This function performs the following transformations:
 * 1. Parses KML text to XML
 * 2. Converts KML to GeoJSON
 * 3. Transforms GeoJSON to Highcharts map series format
 *
 * Each returned series object has map visualization disabled by default
 * and won't affect the overall map view.
 */
export function kmlToSeries(kmlText: string): SeriesOptionsType[] {
  const kmlDoc = new DOMParser().parseFromString(kmlText, 'text/xml');
  const geoJson = kml(kmlDoc);
  const data = Highcharts.geojson(geoJson, 'map');
  return [
    {
      type: 'map',
      affectsMapView: false,
      data,
      tooltip: undefined
    }
  ];
}

export function geoJsonToSeries(geojson: Geojson | undefined): SeriesOptionsType[] {
  if (geojson) {
    const data = Highcharts.geojson(geojson, 'map');
    return [
      {
        type: 'map',
        affectsMapView: false,
        data,
        color: '#00CA004C', //RRGGBBAA
        tooltip: undefined
      }
    ];
  } else {
    return [];
  }
}
