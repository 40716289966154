import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/** Redirects
 * app.spidertracks.io/organisation/{orgId}/settings/events
 * to
 * app.spidertracks.io/organisation/{orgId}/settings/insightRules
 *
 * For situations where the user has the old address saved in their bookmarks / browser history
 * */
const Events = () => {
    const history = useHistory();
    const { organisationId } = useParams();

    useEffect(() => {
        if (organisationId) {
            history.push(`/organisation/${organisationId}/settings/insightRules`);
        }
    }, [organisationId, history]);

    return null; // No UI since it redirects immediately
};

export default Events;
