import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateFilter, getRanges } from '../../common/filters';
import { AircraftFilter } from './AircraftFilter';
import { EventFilter } from './EventFilter';
import { Context as SisenseContext } from '../../../context/ArchivedInsightsSisenseContext';
import { insightsDashboardUnavailableError } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSelectedAircraft,
  setSelectedAircraft,
  getSelectedEventTypes,
  setSelectedEventTypes,
  getDates,
  setDates,
  resetDates
} from '../../../redux/slice/insights';
import moment from 'moment';

/**
 * Todo: Add Sisense types when they become available
 */
const today = new Date().toISOString();

export const SisenseFilters = ({ datasource, aircraftdim, eventTypeDim }) => {
  const dates = useSelector(getDates);
  const selectedAircraft = useSelector(getSelectedAircraft);
  const selectedEventTypes = useSelector(getSelectedEventTypes);
  const dispatch = useDispatch();

  const {
    sisense,
    aircraftFilters,
    eventFilterValues,
    sisenseDashboardReady,
    sisenseDashboardBeingExported
  } = useContext(SisenseContext);
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState('');

  // event filter related
  const [eventSearchText, setEventSearchText] = useState('');
  const [eventFilterVisibility, setEventFilterVisibility] = useState(false);

  const applyAircraftFilter = aircraft => {
    try {
      sisense.dashboard.applyFilters({
        jaql: {
          datasource,
          dim: aircraftdim,
          datatype: 'type',
          filter: {
            members: [...aircraft]
          }
        }
      });
    } catch (e) {
      console.log(e);
      insightsDashboardUnavailableError();
    }
  };

  const applyEventTypeFilter = eventType => {
    try {
      sisense.dashboard.applyFilters({
        jaql: {
          datasource,
          dim: eventTypeDim,
          datatype: 'type',
          filter: {
            members: [...eventType]
          }
        }
      });
    } catch (e) {
      console.log(e);
      insightsDashboardUnavailableError();
    }
  };

  const applyDateFilters = (from, to) => {
    try {
      sisense.dashboard.applyFilters({
        jaql: {
          dim: '[DimDates.Date (Calendar)]',
          datatype: 'datetime',
          level: 'days',
          filter: {
            from,
            to
          }
        }
      });
      applyAircraftFilter(selectedAircraft); // Fixes bug CEX-452, issues is with sisense
      applyEventTypeFilter(selectedEventTypes);
      console.log('Date filters applied');
    } catch (e) {
      console.log(e);
      insightsDashboardUnavailableError();
    }
  };

  useEffect(() => {
    // Requried to run once to update pdf export date filter to one shown in web UI
    if (sisense?.dashboard && sisenseDashboardReady && dates.dashboardFrom && dates.dashboardTo) {
      applyDateFilters(dates.dashboardFrom, dates.dashboardTo);
    }
  }, [sisenseDashboardReady]);

  const updateDateFilter = dates => {
    if (!dates.length) {
      dispatch(resetDates());
      applyDateFilters('2019-01-01', today);
    }
    if (sisense && !!dates.length && dates !== null && dates[0] && dates[1]) {
      const from = dates[0].format('YYYY-MM-DD');
      const to = dates[1].format('YYYY-MM-DD');

      dispatch(
        setDates({
          dashboardFrom: from,
          dashboardTo: to
        })
      );
      applyDateFilters(from, to);
    }
  };

  const clearAircraftFilter = () => {
    dispatch(setSelectedAircraft([]));
    applyAircraftFilter([]);
    setVisible(false);
    setSearchText('');
  };

  const confirmAircraftFilter = () => {
    applyAircraftFilter(selectedAircraft);
    setVisible(false);
    setSearchText('');
  };

  const confirmEventTypeFilter = () => {
    applyEventTypeFilter(selectedEventTypes);
    setEventFilterVisibility(false);
    setEventSearchText('');
  };

  const clearEventTypeFilter = () => {
    dispatch(setSelectedEventTypes([]));
    applyEventTypeFilter([]);
    setEventFilterVisibility(false);
    setEventSearchText('');
  };

  const selectAircraft = aircraft => dispatch(setSelectedAircraft(aircraft));
  const selectEventTypes = eventTypes => dispatch(setSelectedEventTypes(eventTypes));

  let calendarFieldsValues;

  // pass values prop if calendarFieldValues are valid dates
  if (!dates.dashboardFrom && !dates.dashboardTo) {
    calendarFieldsValues = [undefined, undefined];
  } else {
    calendarFieldsValues = [moment(dates.dashboardFrom), moment(dates.dashboardTo)];
  }
  const ranges = getRanges();

  return (
    <React.Fragment>
      <DateFilter
        ranges={ranges}
        handleChange={updateDateFilter}
        calendarFieldsValues={calendarFieldsValues}
        disabled={!sisenseDashboardReady || sisenseDashboardBeingExported}
      />
      <AircraftFilter
        searchText={searchText}
        setSearchText={setSearchText}
        visible={visible}
        setVisible={setVisible}
        filters={aircraftFilters}
        aircraftdim={aircraftdim}
        selectAircraft={selectAircraft}
        selectedAircraft={selectedAircraft}
        clearAircraftFilter={clearAircraftFilter}
        confirmFilter={confirmAircraftFilter}
        disabled={!sisenseDashboardReady || sisenseDashboardBeingExported}
      />
      <EventFilter
        searchText={eventSearchText}
        setSearchText={setEventSearchText}
        visible={eventFilterVisibility}
        setVisible={setEventFilterVisibility}
        filters={eventFilterValues}
        selectEventTypes={selectEventTypes}
        selectedEventTypes={selectedEventTypes}
        clearEventTypeFilter={clearEventTypeFilter}
        confirmFilter={confirmEventTypeFilter}
        disabled={!sisenseDashboardReady || sisenseDashboardBeingExported}
      />
    </React.Fragment>
  );
};

SisenseFilters.propTypes = {
  datasource: PropTypes.string,
  datedim: PropTypes.string,
  aircraftdim: PropTypes.string,
  eventTypeDim: PropTypes.string
};

export default SisenseFilters;
