import { OrganisationMemberFormData, OrganisationMemberFormValues } from './OrganisationMemberForm';
import { getInstance } from '../../../../common/api/spidertracks-sdk';

const getFormattedTagMacAddress = (tagMacAddress: string) =>
  tagMacAddress
    .replace(/:/g, '')
    .replace(/-/g, '')
    .replace(/\./g, '')
    .replace(/ /g, '');

const saveTagChanges = async (
  original: OrganisationMemberFormData,
  formFieldValues: OrganisationMemberFormValues
) => {
  const originalTag = original.personTag;
  const updatedTagMacAddress = formFieldValues.personTag;

  if (!originalTag && !updatedTagMacAddress) {
    return;
  }

  if (!originalTag && updatedTagMacAddress) {
    await getInstance().savePersonAssociatedTag({
      macAddress: getFormattedTagMacAddress(updatedTagMacAddress),
      personId: original.memberId
    });
    return;
  }

  if (originalTag && !updatedTagMacAddress) {
    await getInstance().deletePersonAssociatedTag(originalTag.id);
    return;
  }

  if (originalTag && updatedTagMacAddress) {
    if (originalTag.macAddress === updatedTagMacAddress) {
      return;
    }

    await getInstance().deletePersonAssociatedTag(originalTag.id);
    await getInstance().savePersonAssociatedTag({
      macAddress: getFormattedTagMacAddress(updatedTagMacAddress),
      personId: original.memberId
    });
    return;
  }
};

const arraysEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) {
    return false;
  }

  const sortedA = a.slice().sort();
  const sortedB = b.slice().sort();

  for (let i = 0; i < a.length; i++) {
    if (sortedA[i] !== sortedB[i]) {
      return false;
    }
  }

  return true;
};

const saveAccessibleAircraftChanges = async (
  original: OrganisationMemberFormData,
  formFieldValues: OrganisationMemberFormValues
) => {
  const userService = getInstance().getUserService();

  const selectedAircraft = formFieldValues.aircraft;
  const allAircraft = original.organisationAircraft.map(aircraft => aircraft.value);
  if (arraysEqual(original.selectedAircraft, selectedAircraft)) {
    return;
  }

  if (allAircraft.length === selectedAircraft.length) {
    await userService.assignAircraftAccess(original.memberId, original.organisationId, ['*']);
  } else {
    await userService.assignAircraftAccess(
      original.memberId,
      original.organisationId,
      selectedAircraft
    );
  }
};

const saveRoleChanges = async (
  original: OrganisationMemberFormData,
  formFieldValues: OrganisationMemberFormValues
) => {
  const originalRole = original.role;
  const updatedRole = formFieldValues.role;

  if (originalRole === updatedRole) {
    return;
  }

  await getInstance()
    .getUserService()
    .updateOrganisationMemberRole(original.organisationId, original.memberId, updatedRole);
};

const saveSpiderTxtStatusChanges = async (
  original: OrganisationMemberFormData,
  formFieldValues: OrganisationMemberFormValues
) => {
  const originalStatus = original.spiderTxtStatus;
  const updatedStatus = formFieldValues.spiderTxtStatus;

  if (originalStatus === updatedStatus) {
    return;
  }

  const textingEnabled = updatedStatus === 'Available';
  await getInstance()
    .getUserService()
    .updateSpiderTxtStatus(original.organisationId, original.memberId, textingEnabled);
};

export const saveFormFieldChanges = async (
  original: OrganisationMemberFormData,
  formFieldValues: OrganisationMemberFormValues
) => {
  await Promise.all([
    saveTagChanges(original, formFieldValues),
    saveAccessibleAircraftChanges(original, formFieldValues),
    saveRoleChanges(original, formFieldValues),
    saveSpiderTxtStatusChanges(original, formFieldValues)
  ]);
};
