import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import { MenuIcon } from '../../Flying/Map/MapControls/MenuIcon';
import MapViewIcon from '../../Flying/Map/MapControls/icons/MapViewIcon';
import Icon from '../../Flying/Map/MapControls/Icon';
import { MapViewOptions } from './MapViewOptions';
import { FullState } from '../../../store';
import {
  getActiveKmls,
  getGoogleView,
  getKmlData,
  getSkyVectorView
} from '../../../redux/selectors/mapData';
import { mapView } from '../../../redux/reducers/mapReducer/actions/map';
import { setMapTheme } from '../../../redux/slice/eventsExplorer';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { UserPreferenceKey } from '../../../common/api/spidertracks-sdk/types/UserData';
import MapLayersIcon from '../../Flying/Map/MapControls/icons/MapLayers';
import MapLayerViewOptions from '../../Flying/Map/MapControls/maplayers/MapLayerViewOptionsContainer';
import {
  populateKmlData,
  updateKmlData
} from '../../../redux/reducers/mapReducer/actions/map/thunk';
import { availableMaps } from './AvailableMaps';
import { getUserData } from '../../../redux/selectors/userData';
import { useFeatureFlag } from '../../../hooks';

const MapControlsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-right: 20px;
  top: 8rem;
  right: 0;
`;

const MapViewToChartTheme: Record<string, string> = {
  roadmap: 'WorldStreetMap',
  satellite: 'WorldImagery',
  terrain: 'WorldTopoMap',
  hybrid: 'NatGeoWorldMap',
  basic: 'WorldGrayCanvas',
  vfr: 'SECTIONAL',
  hi: 'HIGH',
  lo: 'LOW'
};

const MapControls = () => {
  const userData = useSelector(getUserData);
  const userOrgIds = userData.orgs?.reduce<string[]>((acc, o) => acc.concat(o.org.id), []);
  const isGeofenceFeatureEnabled = useFeatureFlag('geofence', userOrgIds);

  const kmlData_ = useSelector(getKmlData);
  let kmlData = kmlData_.concat([]);
  const activeKmls = useSelector(getActiveKmls);
  if (isGeofenceFeatureEnabled && !kmlData.some(element => element.name === 'Geofences')) {
    kmlData = [{ id: 'geofence', name: 'Geofences' }].concat(kmlData);
  }
  const [viewVisible, setViewVisible] = useState(false);
  const [layersVisible, setLayersVisible] = useState(false);
  const dispatch = useDispatch();
  const handleViewVisibleChange = (state: boolean) => setViewVisible(state);
  const handleLayersVisibleChange = (state: boolean) => setLayersVisible(state);
  const googleView = useSelector(getGoogleView);
  const skyVectorView = useSelector(getSkyVectorView);

  useEffect(() => {
    dispatch(populateKmlData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialView = googleView || skyVectorView;
    if (initialView) {
      const chartTheme = MapViewToChartTheme[initialView];
      if (chartTheme) {
        dispatch(setMapTheme(chartTheme));
      }
    }
  }, [googleView, skyVectorView]);

  const mapViewChanged = ({ key }: { key: string }) => {
    const mapViewOption = availableMaps.find(option => option.key === key);
    if (mapViewOption) {
      getInstance()
        .saveUserPreference(UserPreferenceKey.MAP_LAYER, {
          mapLayer: key
        })
        .catch(r => console.error(r));

      dispatch(
        mapView({
          googleView: mapViewOption.provider === 'ESRI' ? mapViewOption.value : '',
          skyVectorView: mapViewOption.provider === 'SkyVector' ? mapViewOption.value : ''
        })
      );
    }
    setViewVisible(false);
  };

  return (
    <MapControlsContainer>
      <Tooltip placement={'left'} title={'Map View'}>
        <Popover
          data-testid={'map-view-popover'}
          placement={'leftTop'}
          overlayStyle={{ width: '218px' }}
          content={
            <MapViewOptions onChange={mapViewChanged} initialView={googleView ?? skyVectorView} />
          }
          title={''}
          trigger={'click'}
          visible={viewVisible}
          onVisibleChange={handleViewVisibleChange}
        >
          <MenuIcon>
            <Icon height={18} width={18} type={MapViewIcon} />
          </MenuIcon>
        </Popover>
      </Tooltip>
      <Tooltip placement={'left'} title={'Map Layers'}>
        <Popover
          data-testid={'map-layer-popover'}
          placement={'leftTop'}
          overlayStyle={{ width: '218px' }}
          content={
            <MapLayerViewOptions
              updateKmlData={updateKmlData}
              mapLayers={kmlData}
              activeKmls={activeKmls}
            />
          }
          title={''}
          trigger={'click'}
          visible={layersVisible}
          onVisibleChange={handleLayersVisibleChange}
        >
          <MenuIcon>
            <Icon height={18} width={18} type={MapLayersIcon} />
          </MenuIcon>
        </Popover>
      </Tooltip>
    </MapControlsContainer>
  );
};

const mapState = (state: FullState) => ({
  mapView: state.mapReducer.mapView
});

export default memo(connect(mapState)(MapControls));
