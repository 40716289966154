export const GMAP = () => window.gmap;
export const GOOGLE = () => window.google;

// Standard position report with no event - refers to the small gray dot on the map
export const STANDARD_POINT_GMAP_CONTROLLED_ICON = {
  width: 12,
  height: 12,
  positionX: 6,
  positionY: 6
};

// Event position report
export const EVENT_CONTROLLED_ICON = {
  width: 16,
  height: 16,
  positionX: 8,
  positionY: 8
};

// Aircraft position report
export const GMAP_CONTROLLED_ICON = {
  width: 35,
  height: 35,
  positionX: 17,
  positionY: 17
};

export const ACTIVE_GMAP_CONTROLLED_ICON = {
  width: 55,
  height: 55,
  positionX: 27,
  positionY: 28
};

export const ORGANISATION = '/organisation';
export const HISTORY = '/history';
export const INSIGHTS_ARCHIVED = '/insights-archived';
export const INSIGHTS_SSO_LOGIN = '/sisense/sso-login';
export const FLIGHT = '/flight';
export const INSIGHTS = '/insights';
export const EVENTS_EXPLORER = '/events-explorer';
export const SPIDERTXT = '/newspidertxt';
export const THIRTY_SECONDS = 30000;

export const OLD_SETTINGS_PAGES = [
  'basicinformation',
  'publicpage',
  'kml',
  'spidertxt',
  'members',
  'visibility',
  'notifications',
  'aircraftsettings',
  'aff/api',
  'sossettings',
  'eventlogging',
  'billingandpayments',
  'invoices'
];

/**
 * SpiderTracks Brand Colors 2019
 */
export const BeaconGreenHex = '#BED600';
export const TrackBlackHex = '#000000';
