import React, { useEffect, useState } from 'react';
import { Button, PageHeader } from 'antd';
import { useParams } from 'react-router';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import {
  InvitedUser,
  OrganisationMember
} from '../../../common/api/spidertracks-sdk/private/services/UserService';
import { PersonAssociatedTag } from '../../../common/api/spidertracks-sdk/types/PersonAssociatedTag';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';
import {
  showErrorNotification,
  showSuccessNotification
} from '../../../helpers/globalNotifications';
import { InvitedUsersContainer } from './invited/InvitedUsersContainer';
import InviteUsersDrawer from './invited/InviteUsersDrawer';
import OrganisationMemberDrawer from './members/OrganisationMemberDrawer';
import { OrganisationMembersContainer } from './members/OrganisationMembersContainer';
import { useFeatureFlag } from '../../../hooks';

const style = { overflow: 'scroll', height: '100%' };

const UsersContainer = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const isUserLevelSpidertxtEnabled = useFeatureFlag('spidertxt-users-admin', [organisationId]);
  const isTagsAdminFlagEnabled = useFeatureFlag('manifest-tags-admin', [organisationId]);
  const isGroupsAdminEnabled = useFeatureFlag('groups-users-admin', [organisationId]);

  const [loadingInvitedUsers, setLoadingInvitedUsers] = useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);

  const [loadingOrganisationMembers, setLoadingOrganisationMembers] = useState<boolean>(false);
  const [organisationMembers, setOrganisationMembers] = useState<OrganisationMember[]>([]);
  const [tags, setTags] = useState<PersonAssociatedTag[]>([]);
  const [organisationAircraft, setOrganisationAircraft] = useState<PublicAircraftData[]>([]);
  const [selectedOrganisationMember, setSelectedOrganisationMember] = useState<
    OrganisationMember
  >();
  const [memberDrawerVisible, setMemberDrawerVisible] = useState<boolean>(false);
  const [inviteDrawerVisible, setInviteDrawerVisible] = useState<boolean>(false);

  const loadInvitedUsers = async () => {
    await getInstance()
      .getUserService()
      .getInvitedUsers(organisationId)
      .then(users => setInvitedUsers(users))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to fetch invited users');
      })
      .finally(() => setLoadingInvitedUsers(false));
  };

  const loadOrganisationMembers = async () => {
    await getInstance()
      .getUserService()
      .getOrganisationMembers(organisationId)
      .then(members => setOrganisationMembers(members))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to fetch organisation members');
      })
      .finally(() => setLoadingOrganisationMembers(false));
  };

  const sendInvites = async (emailAddresses: string[], customMessage?: string) => {
    setLoadingInvitedUsers(true);
    await getInstance()
      .getUserService()
      .sendInvites(organisationId, emailAddresses, customMessage)
      .then(() =>
        showSuccessNotification(emailAddresses.length > 1 ? 'Invites sent' : 'Invite sent')
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to send invites');
      });
    setInviteDrawerVisible(false);
    await loadInvitedUsers();
  };

  const cancelInvites = async (inviteIds: string[]) => {
    setLoadingInvitedUsers(true);
    await getInstance()
      .getUserService()
      .cancelInvites(organisationId, inviteIds)
      .then(() =>
        showSuccessNotification(inviteIds.length > 1 ? 'Invites cancelled' : 'Invite cancelled')
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to cancel invites');
      });
    await loadInvitedUsers();
  };

  const removeOrganisationMembers = async (memberIds: string[]) => {
    setLoadingOrganisationMembers(true);
    await getInstance()
      .getUserService()
      .deleteOrganisationMembers(memberIds, organisationId)
      .then(() =>
        showSuccessNotification(
          memberIds.length > 1 ? 'Members removed successfully' : 'Member removed successfully'
        )
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to remove members');
      });
    await loadOrganisationMembers();
  };

  useEffect(() => {
    const userService = getInstance().getUserService();

    const fetchInvitedUsers = async () => {
      await userService.getInvitedUsers(organisationId).then(users => {
        setInvitedUsers(users);
      });
    };

    const fetchOrganisationMembers = async () => {
      const [members, tags, organisationAircraft] = await Promise.all([
        userService.getOrganisationMembers(organisationId),
        getInstance().getPersonAssociatedTags(organisationId),
        getInstance().getOrgAircraft(organisationId)
      ]);

      const formattedTags = tags.map(tag => {
        return {
          ...tag,
          macAddress:
            tag && tag.macAddress
              ? tag.macAddress
                  .replace(/:/g, '')
                  .replace(/-/g, '')
                  .replace(/(.{2})/g, '$1:')
                  .replace(/:$/g, '')
              : '-'
        };
      });

      setOrganisationMembers(members);
      setTags(formattedTags);
      setOrganisationAircraft(organisationAircraft);
    };

    setLoadingInvitedUsers(true);
    setLoadingOrganisationMembers(true);

    fetchInvitedUsers()
      .catch(console.error)
      .finally(() => {
        setLoadingInvitedUsers(false);
      });
    fetchOrganisationMembers()
      .catch(console.error)
      .finally(() => {
        setLoadingOrganisationMembers(false);
      });
  }, [organisationId]);

  useEffect(() => {
    if (selectedOrganisationMember) {
      setMemberDrawerVisible(true);
    }
  }, [selectedOrganisationMember]);

  const handleOrganisationMemberRowClick = (organisationMemberId: string) => {
    if (organisationMemberId) {
      const member = organisationMembers.find(m => m.id === organisationMemberId);
      if (member) {
        setSelectedOrganisationMember(member);
      }
    }
  };

  const handleMemberDrawerClose = () => {
    setMemberDrawerVisible(false);
    setSelectedOrganisationMember(undefined);
  };

  const onSave = async (success: boolean) => {
    setMemberDrawerVisible(false);
    if (success) {
      showSuccessNotification('Member updated successfully');
    } else {
      showErrorNotification('Failed to update member');
    }
    setSelectedOrganisationMember(undefined);
    await loadOrganisationMembers();
  };

  return (
    <div className="px-5 py-4" style={style}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Users"
          subTitle=""
        />
        <Button
          type={'primary'}
          size="large"
          data-testid={'invite-button'}
          onClick={() => setInviteDrawerVisible(true)}
          style={{ padding: '0 50px' }}
        >
          Invite
        </Button>
      </div>
      <div>
        <InvitedUsersContainer
          invitedUsers={invitedUsers}
          loading={loadingInvitedUsers}
          sendInvites={sendInvites}
          cancelInvites={cancelInvites}
        />
        <OrganisationMembersContainer
          organisationMembers={organisationMembers}
          tags={tags}
          loading={loadingOrganisationMembers}
          onRow={handleOrganisationMemberRowClick}
          removeOrganisationMembers={removeOrganisationMembers}
          showTableTitle={invitedUsers.length > 0 || loadingInvitedUsers}
          isUserLevelSpidertxtEnabled={isUserLevelSpidertxtEnabled}
          isTagsAdminFlagEnabled={isTagsAdminFlagEnabled}
          isGroupsAdminEnabled={isGroupsAdminEnabled}
        />
      </div>
      {memberDrawerVisible && selectedOrganisationMember !== undefined && (
        <OrganisationMemberDrawer
          organisationMember={selectedOrganisationMember}
          allTags={tags}
          personTag={tags.find(tag => tag.personId === selectedOrganisationMember.id)}
          organisationAircraft={organisationAircraft}
          onClose={handleMemberDrawerClose}
          onSave={onSave}
          removeOrganisationMembers={removeOrganisationMembers}
          isUserLevelSpidertxtEnabled={isUserLevelSpidertxtEnabled}
          isTagsAdminFlagEnabled={isTagsAdminFlagEnabled}
        />
      )}
      {inviteDrawerVisible && (
        <InviteUsersDrawer
          onClose={() => setInviteDrawerVisible(false)}
          onCreate={(emailAddresses: string, customMessage?: string) => {
            sendInvites(
              emailAddresses
                .toLowerCase()
                .split(',')
                .map(email => email.trim()),
              customMessage
            );
          }}
        />
      )}
    </div>
  );
};

export default UsersContainer;
