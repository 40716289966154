import React, { useEffect, useRef, useState } from 'react';
import { Form, Select, Input, Tooltip, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { ValidationRule, WrappedFormUtils } from 'antd/lib/form/Form';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import PropTypes from 'prop-types';
import { AircraftSelector, ConfirmationButtons } from '../../../common/form';
import { saveFormFieldChanges } from './saveOrganisationMember';
import { PersonAssociatedTag } from '../../../../common/api/spidertracks-sdk/types/PersonAssociatedTag';
import { showRemoveOrganisationMembersModal } from './RemoveOrganisationMembersModal';

export interface OrganisationMemberFormData {
  memberId: string;
  organisationId: string;
  role: string;
  allTags: PersonAssociatedTag[];
  personTag?: {
    id: number;
    macAddress: string;
  };
  organisationAircraft: CheckboxOptionType[];
  selectedAircraft: string[];
  defaultAircraftAccess: boolean;
  spiderTxtStatus: string;
  isOwner: boolean;
}

export interface OrganisationMemberFormValues {
  role: string;
  personTag?: string;
  aircraft: string[];
  spiderTxtStatus: string;
}

interface ComponentProps extends FormComponentProps {
  initialData: OrganisationMemberFormData | null;
  onClose: () => void;
  onSave: (success: boolean) => void;
  removeOrganisationMembers: (memberIds: string[]) => void;
  isUserLevelSpidertxtEnabled: boolean;
  isTagsAdminFlagEnabled: boolean;
  form: WrappedFormUtils;
}

const ROLE_OPTIONS = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Member', value: 'MEMBER' }
];

const TAG_MAC_ADDRESS_REGEX = /((18([:|\-. ])?FD([:|\-. ])?74)|(48([:|\-. ])?A9([:|\-. ])?8A)|(DC([:|\-. ])?2C([:|\-. ])?6E)|(2C([:|\-. ])?C8([:|\-. ])?1B)|(CC([:|\-. ])?2D([:|\-. ])?E0)|(74([:|\-. ])?4D([:|\-. ])?28)|(D4([:|\-. ])?CA([:|\-. ])?6D)|(4C([:|\-. ])?5E([:|\-. ])?0C)|(E4([:|\-. ])?8D([:|\-. ])?8C)|(64([:|\-. ])?D1([:|\-. ])?54)|(48([:|\-. ])?8F([:|\-. ])?5A)|(C4([:|\-. ])?AD([:|\-. ])?34)|(6C([:|\-. ])?3B([:|\-. ])?6B)|(00([:|\-. ])?0C([:|\-. ])?42)|(B8([:|\-. ])?69([:|\-. ])?F4)|(08([:|\-. ])?55([:|\-. ])?31))(([:|\-. ])?([0-9A-F]){2}){3}$/;

export const OrganisationMemberForm: React.FC<ComponentProps> = ({
  initialData,
  onClose,
  onSave,
  removeOrganisationMembers,
  isUserLevelSpidertxtEnabled,
  isTagsAdminFlagEnabled,
  form
}) => {
  const formRef = useRef(form);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    formRef.current.setFields({
      role: { value: initialData.role },
      personTag: { value: initialData.personTag?.macAddress },
      aircraft: {
        value: initialData.selectedAircraft
      },
      spiderTxtStatus: { value: initialData.spiderTxtStatus }
    });
  }, [initialData]);

  const clearTagValue = () => {
    formRef.current.setFieldsValue({ personTag: '' });
  };

  const validateMacAddress = (
    _rule: ValidationRule,
    value: string,
    callback: (message?: string) => {}
  ) => {
    if (value) {
      if (!TAG_MAC_ADDRESS_REGEX.test(value)) {
        callback('Invalid MAC Address');
      }

      if (initialData) {
        const matchingTag = initialData.allTags.find(tag => tag.macAddress === value);
        if (matchingTag) {
          const tagOwnerId = matchingTag.personId;
          if (tagOwnerId !== initialData.memberId) {
            callback('MAC Address already in use');
          }
        }
      }
    }

    callback();
  };

  const macAddressLabel = (
    <span>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>
              Tag MAC Address (optional){' '}
              <Tooltip
                placement="right"
                title={
                  <span>
                    MAC Address from your Bluetooth tag. Please refer this{' '}
                    <a target={'_blank'} href={'https://spidertrackshelp.zendesk.com/hc/en-nz'}>
                      wiki page
                    </a>{' '}
                    for more info. Example valid MAC Address formats : 18:FD:74:00:00:00,
                    18FD74000000
                  </span>
                }
              >
                <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
              </Tooltip>
            </td>
            <td style={{ textAlign: 'right' }}>
              <Button color="primary" type="link" style={{ padding: 0 }} onClick={clearTagValue}>
                Clear
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </span>
  );

  const aircraftVisibilityLabel = (
    <span>
      Aircraft Visibility{' '}
      <Tooltip
        placement="right"
        title={
          <span>
            Visibility allows you to select which members within your Organisation can view tracks
            from your aircraft. It allows you to set members who can see all aircraft by selecting
            the all aircraft option in the drop down selection. You can also allow additional
            members to see individual aircraft by selecting the aircraft, checking the tick box for
            the members, and clicking save.
          </span>
        }
      >
        <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
      </Tooltip>
    </span>
  );

  const saveOrganisationMember = async (formFieldValues: OrganisationMemberFormValues) => {
    setIsSaving(true);

    let success = true;
    if (initialData && formFieldValues) {
      try {
        await saveFormFieldChanges(initialData, formFieldValues);
      } catch (e) {
        success = false;
      }
    }

    setIsSaving(false);
    onSave(success);
  };

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formRef.current.validateFields((err, values) => {
      if (err) {
        return;
      }

      saveOrganisationMember(values);
    });
  };

  return (
    <div>
      <Form onSubmit={formSubmit} layout="vertical">
        <Form.Item label="Select Role">
          {formRef.current.getFieldDecorator('role')(
            <Select>
              {ROLE_OPTIONS.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {isTagsAdminFlagEnabled && (
          <Form.Item label={macAddressLabel}>
            {formRef.current.getFieldDecorator('personTag', {
              validateFirst: true,
              rules: [{ validator: validateMacAddress }]
            })(<Input placeholder="Tag MAC Address (optional)" />)}
          </Form.Item>
        )}

        <AircraftSelector
          form={formRef.current}
          initialValue={initialData ? initialData.selectedAircraft : []}
          isEditForm={false}
          options={initialData ? initialData.organisationAircraft : []}
          showAllChoice={false}
          showFixedWingChoice={false}
          showRotaryChoice={false}
          label={aircraftVisibilityLabel}
          allowNoneSelection={true}
        />

        {isUserLevelSpidertxtEnabled && (
          <Form.Item label="Spidertxt">
            {formRef.current.getFieldDecorator('spiderTxtStatus')(
              <Select>
                <Select.Option value="Available">Available</Select.Option>
                <Select.Option value="Unavailable">Unavailable</Select.Option>
              </Select>
            )}
          </Form.Item>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <Button
            type="danger"
            size="large"
            style={{ width: '85px', margin: '16px 8px 0 0' }}
            hidden={initialData?.isOwner}
            onClick={() => {
              if (initialData) {
                showRemoveOrganisationMembersModal({
                  memberIds: [initialData.memberId],
                  removeOrganisationMembers
                });
              }
            }}
          >
            Remove
          </Button>
          <ConfirmationButtons onClose={onClose} isSaving={isSaving} />
        </div>
      </Form>
    </div>
  );
};

OrganisationMemberForm.propTypes = {
  initialData: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  removeOrganisationMembers: PropTypes.func.isRequired,
  isUserLevelSpidertxtEnabled: PropTypes.bool.isRequired,
  isTagsAdminFlagEnabled: PropTypes.bool.isRequired,
  form: PropTypes.any.isRequired
};
