import React from 'react';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

interface ComponentProps {
  inviteEmails: string[];
  resendInvites: (inviteIds: string[]) => void;
}

const getTitle = (inviteIds: string[]) =>
  inviteIds.length > 1 ? 'Resend Invites' : 'Resend Invite';

const getContent = (inviteIds: string[]) =>
  inviteIds.length > 1 ? (
    <>
      <p>Are you sure you want to resend the selected invites?</p>
    </>
  ) : (
    <>
      <p>Are you sure you want to resend the selected invite?</p>
    </>
  );

export const showResendInvitesModal = ({ inviteEmails, resendInvites }: ComponentProps) =>
  Modal.confirm({
    title: getTitle(inviteEmails),
    icon: <ExclamationCircleFilled />,
    content: getContent(inviteEmails),
    okText: inviteEmails.length > 1 ? 'Resend Invites' : 'Resend Invite',
    okButtonProps: { type: 'primary' },
    onOk() {
      resendInvites(inviteEmails);
    }
  });
