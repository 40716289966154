import { useEffect, useState } from 'react';
import { ScriptLoadStatus as Status } from '../types/ScriptLoadStatus';

// Hook
export const useScript = (src: string, id: string): Status => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState<Status>(src ? Status.loading : Status.idle);

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus(Status.idle);
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another instance of this hook
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.setAttribute('src', src);
        script.setAttribute('async', 'true');
        script.setAttribute('data-status', Status.loading);
        if (!!id) {
          script.setAttribute('id', id);
        }
        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          if (script !== null) {
            script.setAttribute('data-status', event.type === 'load' ? Status.ready : Status.error);
          }
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        const currentStatus = script.getAttribute('data-status');
        if (currentStatus === 'idle') {
          setStatus(Status.idle);
        }
        if (currentStatus === 'loading') {
          setStatus(Status.loading);
        }
        if (currentStatus === 'ready') {
          setStatus(Status.ready);
        }
        if (currentStatus === 'error') {
          setStatus(Status.error);
        }
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? Status.ready : Status.error);
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src, id] // Only re-run effect if script src changes
  );

  return status;
};

export default useScript;
