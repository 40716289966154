import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  AnimationOptionsObject,
  ChartOptions,
  PlotMappointOptions,
  Point,
  Series,
  SeriesMapOptions,
  SeriesMappointDataOptions,
  SeriesMappointOptions,
  SeriesOptionsType
} from 'highcharts';
import Dashboards from '@highcharts/dashboards';

import { EventExplorerEvent } from '../../../common/api/spidertracks-sdk/types/EventExplorerEvent';
import {
  getMapTheme,
  setEventsLoadedCount,
  setEventsLoadingError,
  setEventsLoadingStatus,
  setEventsTotalCount
} from '../../../redux/slice/eventsExplorer';
import { EventSeverity, EventSubtype, UserData } from '../../../redux/types';
import { getDateTimeFormat, getUserData } from '../../../redux/selectors/userData';

import { convertMetresTo } from '../../Flying/Map/utils/helper';
import {
  enumToTitleCase,
  enumToTitleCaseWithAcronyms,
  eventTypeAcronyms
} from '../../FlightExplorer/utilities/stringUtilities';

import { getEventsData } from '../getData';

import { EventExplorerDashboardFilters } from './EventsExplorerDashboard';
import { getIconForEvent, getIconForInsightsEvents } from '../../Flying/Map/utils/drawing/marker';
import { availableMaps } from '../MapControls/AvailableMaps';
import { useActiveKmls } from './useActiveKmls';
import { showErrorNotification } from '../../../helpers/globalNotifications';
import { useGeofences } from './useGeofences';
import { geoJsonToSeries, kmlToSeries } from './toSeries';

// -------------------

// Typings for Highcharts are incomplete, so we need to extend them:
interface ClusterPoint extends Point {
  clusterPointsAmount?: number;
  clusteredData: {
    dataIndex: number;
    options: SeriesMappointDataOptions;
  }[];
}

// -------------------

interface EventExplorerSeriesMappointDataOptions extends SeriesMappointDataOptions {
  marker?: {
    symbol: string;
    width?: number;
    height?: number;
  };
  tooltipContent: string;
}

const severityColorMap = {
  [EventSeverity.INSIGHTS__HIGH]: 4,
  [EventSeverity.INSIGHTS__MEDIUM]: 3,
  [EventSeverity.INSIGHTS__LOW]: 2,
  [EventSeverity.INSIGHTS__NOT_SPECIFIED]: 1,
  /*  //LATER: #AVIONICS add these when needed:
  AVIONICS__WARNING: 4?,
  AVIONICS__CAUTION: 3?,
  AVIONICS__ADVISORY: 2?,
  */
  UNKNOWN: 0
};

function getSeverityColorClass(severity?: EventSeverity): string {
  return `severity-${severityColorMap[severity ?? 'UNKNOWN']}`;
}

function worstSeverityColour(a?: string, b?: string): string | undefined {
  const aNum = +(a?.match(/\d+/)?.[0] ?? '');
  const bNum = +(b?.match(/\d+/)?.[0] ?? '');
  return `severity-${Math.max(aNum, bNum)}`;
}

function formatEventType(eventType: string, eventSubtype?: EventSubtype): string {
  return `${enumToTitleCase(eventType)}${
    eventSubtype ? `: ${enumToTitleCaseWithAcronyms(eventSubtype, eventTypeAcronyms)}` : ''
  }`;
}

const margin = 8;
const lineSpacing = 18;
const tableCol2 = 75;
const SVG_MARKER_ICON_SIZE = { width: 24, height: 24 };
const WORLD_MULTIPOINT_GEOMETRY = {
  type: 'MultiPoint',
  coordinates: [
    // Alaska west
    [-164, 54],
    // Greenland north
    [-35, 84],
    // New Zealand east
    [179, -38],
    // Chile south
    [-68, -65]
  ]
};

export function mapEventToPoint(
  userData: UserData,
  dateTimeFormat: string,
  event: EventExplorerEvent
): EventExplorerSeriesMappointDataOptions {
  const eventDate = moment(event.eventTime).tz(userData.timezone);
  const asAt = eventDate.format(dateTimeFormat);

  const aglString =
    event.altitudeAgl !== undefined
      ? `<tspan x="${margin}" dy="${lineSpacing}" class="b">Altitude</tspan><tspan x="${tableCol2}">${convertMetresTo(
          event.altitudeAgl,
          userData.altitudeUnit
        ).join('')} AGL</tspan>`
      : '';

  const rego = event.aircraftRegistration?.replace(/_/g, ' ');
  const name = formatEventType(event.eventType, event.eventSubtype);

  let svgDataURI;
  let marker;

  if (event.eventType === 'INSIGHTS') {
    svgDataURI = getIconForInsightsEvents(
      event.severityLevel ?? EventSeverity.INSIGHTS__NOT_SPECIFIED
    );
  } else {
    svgDataURI = getIconForEvent(event.eventType, event.eventSubtype);
  }

  if (svgDataURI) {
    marker = {
      symbol: 'url(' + svgDataURI + ')',
      width: SVG_MARKER_ICON_SIZE.width,
      height: SVG_MARKER_ICON_SIZE.height
    };
  } else {
    marker = {
      symbol: 'circle'
    };
  }

  return {
    id: `${event.id}`,
    lat: event.location.lat,
    lon: event.location.lon,
    dataLabels: {
      className: getSeverityColorClass(event.severityLevel)
    },
    name,
    marker: marker,
    tooltipContent:
      `<tspan x="${margin}" class="b">Aircraft</tspan><tspan x="${tableCol2}">${rego}</tspan>` +
      `<tspan x="${margin}" dy="${lineSpacing}" class="b">Time</tspan><tspan x="${tableCol2}">${asAt}</tspan>` +
      aglString +
      `<tspan x="${margin}" dy="${lineSpacing}" class="b">Event</tspan><tspan x="${tableCol2}">${name}</tspan>` +
      `<tspan x="${tableCol2}" dy="${lineSpacing}"><a href="/history/${event.serialNumber}/${event.bootCount}/flight-explorer?3dfr" target="_blank">Explore &gt;</a></tspan>`
  };
}

// -------------------

export const BATCH_FETCH_LIMIT = 1000;
const EVENT_SERIES_NAME = 'Events';

export const populateEventLocationDataInSeries = async (
  series: Series,
  filters: EventExplorerDashboardFilters,
  cancel: () => boolean,
  dispatch: React.Dispatch<unknown>,
  userData: UserData,
  dateTimeFormat: string
) => {
  let offset = 0;
  let fetchedCount = 0;
  const points: SeriesMappointDataOptions[] = [];

  // Curry the function with userData:
  const eventToPoint = mapEventToPoint.bind(null, userData, dateTimeFormat);

  dispatch(setEventsLoadingStatus(true));

  let eventsDataResponse = await getEventsData({
    ...filters,
    offset,
    limit: BATCH_FETCH_LIMIT
  });
  if (eventsDataResponse.error) {
    console.error(eventsDataResponse.error);
    dispatch(setEventsLoadingError(eventsDataResponse.error));
    dispatch(setEventsLoadingStatus(false));
    return;
  }

  dispatch(setEventsLoadingError(undefined));
  // Total will be set when offset is 0:
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const total = eventsDataResponse.total!;
  dispatch(setEventsTotalCount(total));

  if (!total) {
    dispatch(setEventsLoadedCount({ loaded: 0, flights: 0 }));
    dispatch(setEventsLoadingStatus(false));
    return;
  }

  fetchedCount += eventsDataResponse.events.length;
  offset += eventsDataResponse.events.length;
  const flightsSet = new Set<string>(
    eventsDataResponse.events.map(event => `${event.serialNumber}/${event.bootCount}`)
  );
  points.push(...eventsDataResponse.events.map(eventToPoint));
  series.setData(points);
  dispatch(setEventsLoadedCount({ loaded: fetchedCount, flights: flightsSet.size }));

  while (fetchedCount < total && !cancel()) {
    eventsDataResponse = await getEventsData({
      ...filters,
      offset,
      limit: BATCH_FETCH_LIMIT
    });
    fetchedCount += eventsDataResponse.events.length;
    offset += eventsDataResponse.events.length;
    points.push(...eventsDataResponse.events.map(eventToPoint));

    series.setData(points);

    eventsDataResponse.events
      .map(event => `${event.serialNumber}/${event.bootCount}`)
      .forEach(f => flightsSet.add(f)); //NOTE: can't just `forEach(flightSet.add)` because it explodes.
    dispatch(setEventsLoadedCount({ loaded: fetchedCount, flights: flightsSet.size }));
  }

  dispatch(setEventsLoadingStatus(false));
};

export const useDashboardOptions = (
  filters: EventExplorerDashboardFilters,
  cancel: () => boolean
): Dashboards.Board.Options => {
  const dispatch = useDispatch();

  const userData = useSelector(getUserData);
  const dateTimeFormat = useSelector(getDateTimeFormat);
  const mapTheme = useSelector(getMapTheme);
  const mapProvider = availableMaps.find(option => option.theme === mapTheme);
  const [dataLoadRequired, setDataLoadRequired] = useState(false);

  const { layers, failedLayers } = useActiveKmls();
  const kmlSeries = layers.flatMap(kmlToSeries);

  const geofences = useGeofences();
  const geofenceSeries = geoJsonToSeries(geofences);

  useEffect(() => {
    setDataLoadRequired(true);
  }, [filters]);

  useEffect(() => {
    if (failedLayers.length > 0) {
      showErrorNotification(`Unable to load KML layers ${failedLayers.join(',')}`, 10);
    }
  }, [failedLayers]);

  const animation: AnimationOptionsObject = {
    duration: 450,
    easing: 'linear'
  };

  const mapSeries = (): SeriesOptionsType => {
    if (mapProvider) {
      // https://www.highcharts.com/forum/viewtopic.php?t=53162
      return {
        type: 'tiledwebmap',
        name: mapProvider.key,
        provider: {
          url: mapProvider.url
        }
      };
    }
    return {
      type: 'tiledwebmap',
      provider: {
        type: 'Esri'
      }
    };
  };

  const eventsLayer: SeriesMappointOptions = {
    type: 'mappoint',
    name: `${EVENT_SERIES_NAME}`,
    animation,
    enableMouseTracking: true,
    accessibility: {
      point: {
        descriptionFormat:
          '{#if isCluster}' +
          'Grouping of {clusterPointsAmount} events.' +
          '{else}' +
          '{tooltipContent}' + //TEST: where does this go and does it need to be HTML-stripped?
          '{/if}'
      }
    },
    colorKey: 'clusterPointsAmount',
    data: [],
    color: 'blue',
    marker: {
      lineWidth: 1,
      lineColor: '#FFF',
      radius: 10
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.tooltipContent}',
      clusterFormat:
        '<b>Clustered events:</b> {point.clusterPointsAmount}' +
        '{#if point.eventsList}' +
        `<tspan x="${margin}" dy="${lineSpacing * 1.5}">&ZeroWidthSpace;</tspan>` +
        '{point.eventsList}' +
        '{/if}'
    },
    dataLabels: {
      animation: false,
      verticalAlign: 'top'
    }
  };

  const map: SeriesMapOptions = {
    type: 'map',
    enableMouseTracking: false,
    data: [],
    color: '#2A70FF4d',
    borderColor: '#2A70FF',
    borderWidth: 2
  };

  const dataLoadCompletedCallback = () => {
    setDataLoadRequired(false);
  };

  const chartOptions: ChartOptions = {
    animation,
    events: {
      load: function() {
        const eventSeries = this.series.find(series => series.name === EVENT_SERIES_NAME);
        if (eventSeries && filters && filters.organisationIds.length && filters.aircraft.length) {
          populateEventLocationDataInSeries(
            eventSeries,
            filters,
            cancel,
            dispatch,
            userData,
            dateTimeFormat
          ).catch(console.error);
        }
      },
      render: function() {
        const series = this.series[this.series.length - 1];
        const options = series.options as SeriesMappointOptions;
        if (!options.data?.length) {
          return;
        }

        series.points.forEach(point => {
          const cluster = point as ClusterPoint;
          if (cluster.clusterPointsAmount) {
            const colour = cluster.clusteredData.reduce<string | undefined>(
              (prevSev, point) => worstSeverityColour(prevSev, point.options.dataLabels?.className),
              undefined
            );
            if (cluster.clusteredData.length <= 5) {
              // @ts-ignore
              cluster.eventsList = cluster.clusteredData
                // @ts-ignore
                .map(x => x.options.tooltipContent)
                .reverse()
                .join(`<tspan x="${margin}" dy="${lineSpacing * 1.5}">&ZeroWidthSpace;</tspan>`);
            }
            if (colour) {
              // Remove first just in case it's a re-render and it was there already:
              cluster.graphic?.removeClass(colour);
              cluster.graphic?.addClass(colour);
            }
          } else {
            // @ts-ignore
            point.dataLabel.text.attr('y', 27);
            point.graphic?.addClass('eventPoint');
            // @ts-ignore
            point.graphic?.addClass(point.options.dataLabels?.className);
          }
        });
      }
    }
  };

  const mappoint: PlotMappointOptions = {
    cluster: {
      enabled: true,
      allowOverlap: false,
      animation,
      layoutAlgorithm: {
        type: 'grid',
        gridSize: 100
      },
      zones: [
        {
          from: 1,
          to: 4,
          marker: {
            radius: 13
          }
        },
        {
          from: 5,
          to: 9,
          marker: {
            radius: 15
          }
        },
        {
          from: 10,
          to: 15,
          marker: {
            radius: 17
          }
        },
        {
          from: 16,
          to: 20,
          marker: {
            radius: 19
          }
        },
        {
          from: 21,
          to: 100,
          marker: {
            radius: 21
          }
        },
        {
          from: 101,
          to: 1000,
          marker: {
            radius: 23
          }
        },
        {
          from: 1001,
          to: 10000,
          marker: {
            radius: 25
          }
        },
        {
          from: 10001,
          to: 1000000,
          marker: {
            radius: 27
          }
        }
      ]
    }
  };

  return {
    dataPool: {
      connectors: [
        {
          id: 'events-data',
          type: 'JSON',
          options: {
            data: []
          }
        }
      ]
    },
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: 'dashboard-events-data'
                }
              ]
            }
          ]
        }
      ]
    },
    components: [
      {
        id: 'world-map',
        renderTo: 'dashboard-events-data',
        type: 'Highcharts',
        chartConstructor: 'mapChart',
        chartOptions: {
          tooltip: {
            hideDelay: 1500,
            style: {
              pointerEvents: 'auto'
            }
          },
          chart: chartOptions,
          series: [mapSeries(), ...kmlSeries, ...geofenceSeries, eventsLayer],
          plotOptions: {
            mappoint,
            map,
            series: {
              dataSorting: {
                enabled: false
              }
            }
          },
          title: {
            text: undefined
          },
          legend: {
            enabled: false
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              align: 'right',
              alignTo: 'spacingBox',
              verticalAlign: 'bottom',
              width: 30,
              height: 30,
              x: -5
            }
          },
          mapView: {
            fitToGeometry: WORLD_MULTIPOINT_GEOMETRY,
            maxZoom: mapProvider?.maxZoom ?? 0,
            projection: {
              name: 'WebMercator' // Projection is required for custom URL
            }
          }
        },
        sync: {
          highlight: true,
          visibility: true
        }
      }
    ]
  };
};
