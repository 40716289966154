import React from 'react';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

interface ComponentProps {
  inviteIds: string[];
  cancelInvites: (inviteIds: string[]) => void;
}

const getTitle = (inviteIds: string[]) =>
  inviteIds.length > 1 ? 'Cancel Invites' : 'Cancel Invite';

const getContent = (inviteIds: string[]) =>
  inviteIds.length > 1 ? (
    <>
      <p>Are you sure you want to cancel the selected invites?</p>
      <p>
        This will remove the invites from the list. You would need to send a fresh invite to add the
        users back into the organisation.
      </p>
    </>
  ) : (
    <>
      <p>Are you sure you want to cancel the selected invite?</p>
      <p>
        This will remove the invite from the list. You would need to send a fresh invite to add the
        user back into the organisation.
      </p>
    </>
  );

export const showCancelInvitesModal = ({ inviteIds, cancelInvites }: ComponentProps) =>
  Modal.confirm({
    title: getTitle(inviteIds),
    icon: <ExclamationCircleFilled />,
    content: getContent(inviteIds),
    okText: inviteIds.length > 1 ? 'Cancel Invites' : 'Cancel Invite',
    okButtonProps: { type: 'danger' },
    onOk() {
      cancelInvites(inviteIds);
    }
  });
