import { createSlice } from '@reduxjs/toolkit';
import {
  DisplayInsightRuleCondition,
  InsightRule,
  InsightRulesState,
  InsightRuleToCreate
} from '../../types/insightRules';
import { Dispatch } from 'react';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { createDisplayCondition, createEntityCondition } from '../mapper';
import { UserAltitudeUnit, UserData } from '../../common/api/spidertracks-sdk/types/UserData';
import { PointGroupingOption } from '../../components/Flying/Map/GoogleMap/constants';
import {
  InsightRuleCondition,
  InsightRuleConditionType,
  InsightRuleEntity,
  InsightRuleEntityToCreate
} from '../../common/api/spidertracks-sdk/private/services/InsightRulesService';

export const userDataInitialState: UserData = {
  altitudeUnit: UserAltitudeUnit.METERS,
  city: '',
  countryCode: '',
  createdDate: '',
  dateFormat: '',
  displayName: '',
  distanceUnit: '',
  email: '',
  firstName: '',
  flyingPagePromptShown: true,
  gender: '',
  hideMapRadiusShading: false,
  id: '',
  lastName: '',
  latLongFormat: '',
  orgs: [],
  phones: [],
  speedUnit: '',
  spiderTxtEnabled: true,
  spiderTxtId: 0,
  states: {
    groupTrackPoints: false,
    mapLayer: '',
    pointGroupingOption: PointGroupingOption.LAST_POINT,
    showAllPoints: false,
    sidebarTab: '',
    sidebarVisible: true,
    tcVersion: '',
    trackLineBold: true,
    trackLineGrayUnderline: true,
    trackLineMatchAircraftColour: true
  },
  timeOffset: 0,
  timezone: ''
};

const initialState: InsightRulesState = {
  organisationRules: {},
  userData: userDataInitialState,
  eventParameters: [
    {
      eventClass: 'fsi',
      eventType: 'excessive_g_force',
      parameters: [
        {
          op: 'gt',
          unit: 'g',
          displayName: 'G Force',
          maxDisplayValue: 16,
          name: InsightRuleConditionType.gForce,
          displayUnit: 'g',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_down',
      parameters: [
        {
          op: 'lt',
          unit: 'radians',
          displayName: 'Pitch',
          name: InsightRuleConditionType.pitchDown,
          maxDisplayValue: -10,
          displayUnit: 'degrees',
          minDisplayValue: -180,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_down_at_low_altitude_agl',
      parameters: [
        {
          op: 'lt',
          unit: 'radians',
          displayName: 'Pitch',
          name: InsightRuleConditionType.pitchDown,
          maxDisplayValue: -10,
          displayUnit: 'degrees',
          minDisplayValue: -180,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: InsightRuleConditionType.lowAltitudeAgl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_up',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Pitch',
          name: InsightRuleConditionType.pitchUp,
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_up_at_low_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Pitch',
          name: InsightRuleConditionType.pitchUp,
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: InsightRuleConditionType.lowAltitudeAgl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: 0,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_pitch_up_at_low_speed',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Pitch',
          name: InsightRuleConditionType.pitchUp,
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'm/sec',
          displayName: 'Ground Speed',
          name: InsightRuleConditionType.lowSpeed,
          maxDisplayValue: 10000,
          displayUnit: 'kt',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_rate_of_climb',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: InsightRuleConditionType.rateOfClimb,
          maxDisplayValue: 100000,
          displayUnit: 'ft/min',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_roll',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Roll',
          name: InsightRuleConditionType.roll,
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_roll_at_low_altitude',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Roll',
          name: InsightRuleConditionType.roll,
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude',
          name: InsightRuleConditionType.lowAltitudeAmsl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: -2000,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_roll_at_low_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'radians',
          displayName: 'Roll',
          name: InsightRuleConditionType.roll,
          maxDisplayValue: 180,
          displayUnit: 'degrees',
          minDisplayValue: 10,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: InsightRuleConditionType.lowAltitudeAgl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_rpm',
      parameters: [
        {
          op: 'gt',
          unit: 'rpm',
          displayName: 'RPM',
          maxDisplayValue: 100000,
          name: InsightRuleConditionType.rpm,
          displayUnit: 'rpm',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_speed',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Speed',
          maxDisplayValue: 10000,
          name: InsightRuleConditionType.highSpeed,
          displayUnit: 'kt',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'excessive_temperature',
      parameters: [
        {
          op: 'gt',
          unit: '°C',
          displayName: 'Temperature',
          maxDisplayValue: 100000,
          name: InsightRuleConditionType.temperature,
          displayUnit: '°C',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'high_rate_of_descent',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: InsightRuleConditionType.rateOfDescent,
          maxDisplayValue: 10000,
          displayUnit: 'ft/min',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'high_rate_of_descent_at_low_altitude',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: InsightRuleConditionType.rateOfDescent,
          maxDisplayValue: 10000,
          displayUnit: 'ft/min',
          minDisplayValue: 1,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude',
          name: InsightRuleConditionType.lowAltitudeAmsl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: -2000,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'high_rate_of_descent_at_low_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'm/sec',
          displayName: 'Vertical Speed',
          name: InsightRuleConditionType.rateOfDescent,
          maxDisplayValue: 10000,
          displayUnit: 'ft/min',
          minDisplayValue: 1,
          type: 'numeric'
        },
        {
          op: 'lt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: InsightRuleConditionType.lowAltitudeAgl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'maximum_altitude_agl',
      parameters: [
        {
          op: 'gt',
          unit: 'meters',
          displayName: 'Altitude (AGL)',
          name: InsightRuleConditionType.highAltitudeAgl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: 1,
          type: 'numeric'
        }
      ]
    },
    {
      eventClass: 'fsi',
      eventType: 'maximum_altitude_amsl',
      parameters: [
        {
          op: 'gt',
          unit: 'meters',
          displayName: 'Altitude',
          name: InsightRuleConditionType.highAltitudeAmsl,
          maxDisplayValue: 100000,
          displayUnit: 'ft',
          minDisplayValue: -2000,
          type: 'numeric'
        }
      ]
    }
  ]
};

export const insightRulesSlice = createSlice({
  name: 'insightRules',
  initialState,
  reducers: {
    setRulesForOrg: (
      state,
      action: { payload: { userData: UserData; organisationId: string; rules: InsightRule[] } }
    ) => {
      state.organisationRules[action.payload.organisationId] = action.payload.rules ?? [];
      state.userData = action.payload.userData;
    },
    reset: state => {
      state.organisationRules = {};
    }
  }
});

export function fetchInsightRules(organisationId: string) {
  return async (dispatch: Dispatch<any>) => {
    const sdk = getInstance();
    let entities = await sdk.getInsightRulesService().getAllInsightRules(organisationId);
    const userData = await sdk.getUsers();

    const rules: InsightRule[] = [];
    for (const entity of entities) {
      const primary = createDisplayCondition(entity.primaryCondition, userData);
      let secondary: DisplayInsightRuleCondition | undefined;
      if (entity.secondaryCondition) {
        secondary = createDisplayCondition(entity.secondaryCondition, userData);
      }
      rules.push({ ...entity, primaryCondition: primary, secondaryCondition: secondary });
    }

    dispatch(setRulesForOrg({ userData, organisationId, rules }));
  };
}

export function deleteInsightRules(
  ids: { organisationId: string; ruleId: string }[],
  callback?: Function
) {
  return async (dispatch: Dispatch<any>) => {
    if (ids.length === 0) {
      return;
    }
    const sdk = getInstance();
    await Promise.all(
      ids.map(el => sdk.getInsightRulesService().deleteInsightRule(el.organisationId, el.ruleId))
    );
    fetchInsightRules(ids[0].organisationId)(dispatch);
    if (callback) {
      callback();
    }
    return;
  };
}

export function updateInsightRule(rule: InsightRule, userData: UserData, callback?: Function) {
  return async (dispatch: Dispatch<any>) => {
    const sdk = getInstance();

    const primary = createEntityCondition(rule.primaryCondition, userData);
    let secondary: InsightRuleCondition | undefined;
    if (rule.secondaryCondition) {
      secondary = createEntityCondition(rule.secondaryCondition, userData);
    }

    const entity: InsightRuleEntity = {
      ...rule,
      primaryCondition: primary,
      secondaryCondition: secondary
    };

    await sdk.getInsightRulesService().updateInsightRule(entity);
    fetchInsightRules(rule.organisationId)(dispatch);
    if (callback) {
      callback();
    }
    return;
  };
}

export function createInsightRule(
  rule: InsightRuleToCreate,
  userData: UserData,
  callback?: Function
) {
  return async (dispatch: Dispatch<any>) => {
    const sdk = getInstance();

    const primary = createEntityCondition(rule.primaryCondition, userData);
    let secondary: InsightRuleCondition | undefined;
    if (rule.secondaryCondition) {
      secondary = createEntityCondition(rule.secondaryCondition, userData);
    }

    const entity: InsightRuleEntityToCreate = {
      ...rule,
      primaryCondition: primary,
      secondaryCondition: secondary
    };

    await sdk.getInsightRulesService().createInsightRule(entity);
    fetchInsightRules(rule.organisationId)(dispatch);
    if (callback) {
      callback();
    }
    return;
  };
}

export const { setRulesForOrg, reset } = insightRulesSlice.actions;
