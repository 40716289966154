import { Amplify } from '@spidertracks/common';
import { ServiceAbstract } from '../../ServiceAbstract';

export enum InsightRuleConditionType {
  pitchDown = 'pitchDown',
  pitchUp = 'pitchUp',
  lowAltitudeAgl = 'lowAltitudeAgl',
  lowAltitudeAmsl = 'lowAltitudeAmsl',
  lowSpeed = 'lowSpeed',
  highSpeed = 'highSpeed',
  rateOfClimb = 'rateOfClimb',
  roll = 'roll',
  rateOfDescent = 'rateOfDescent',
  highAltitudeAgl = 'highAltitudeAgl',
  highAltitudeAmsl = 'highAltitudeAmsl',
  gForce = 'gForce',
  temperature = 'temperature',
  rpm = 'rpm'
}

export interface InsightRuleThreshold {
  thresholdValue: number;
  severityEnabled: boolean;
  thresholdValueLow: number;
  thresholdValueMedium: number;
  thresholdValueHigh: number;
}

export enum InsightRuleConditionSource {
  aglMetres = 'aglMetres',
  amslMetres = 'amslMetres',
  aircraftRollRadians = 'aircraftRollRadians',
  aircraftPitchRadians = 'aircraftPitchRadians',
  aircraftYawRadians = 'aircraftYawRadians',
  verticalSpeedMetresPerSecond = 'verticalSpeedMetresPerSecond',
  gForce = 'gForce',
  engine1Cht1DegreesC = 'engine1Cht1DegreesC',
  engine1Egt1DegreesC = 'engine1Egt1DegreesC',
  engine1Rpm = 'engine1Rpm',
  indicatedAirSpeedMetresPerSecond = 'indicatedAirSpeedMetresPerSecond',
  groundSpeedMetresPerSecond = 'groundSpeedMetresPerSecond'
}

export interface InsightRuleCondition {
  type: InsightRuleConditionType;
  source: InsightRuleConditionSource;
  threshold: InsightRuleThreshold;
}

interface RuleBase {
  id: string;
  name: string;
  enabled: boolean;
  organisationId: string;
  aircraftIds: string[];
  allAircraft: boolean;
}

export interface InsightRuleEntity extends RuleBase {
  /// AKA trigger. We can have other conditions too.
  primaryCondition: InsightRuleCondition;
  secondaryCondition: InsightRuleCondition | undefined;
}

export type InsightRuleEntityToCreate = Omit<InsightRuleEntity, 'id'>;

export class InsightRulesService extends ServiceAbstract {
  public async getAllInsightRules(organisationId: string): Promise<InsightRuleEntity[]> {
    return await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `internal/organisations/${organisationId}/insight-rules`
    );
  }

  public async deleteInsightRule(organisationId: string, ruleId: string): Promise<void> {
    return await Amplify.API.del(
      'api.spidertracks.io-authenticated',
      `internal/organisations/${organisationId}/insight-rules/${ruleId}`
    );
  }

  public async updateInsightRule(rule: InsightRuleEntity): Promise<void> {
    return await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `internal/organisations/${rule.organisationId}/insight-rules/${rule.id}`,
      {
        body: rule
      }
    );
  }

  public async createInsightRule(rule: InsightRuleEntityToCreate): Promise<void> {
    return await Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `internal/organisations/${rule.organisationId}/insight-rules`,
      {
        body: rule
      }
    );
  }
}
