import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highmaps';
import MarkerClusters from 'highcharts/modules/marker-clusters';
import TiledWebMap from 'highcharts/modules/tiledwebmap';

import Dashboards from '@highcharts/dashboards';
import DataGrid from '@highcharts/dashboards/datagrid';
import LayoutModule from '@highcharts/dashboards/modules/layout';

import { EventSeverity, EventSubtype } from '../../../redux/types';

import { useDashboardOptions } from './useDashboardOptions';

import './styles.scss';
import MapControls from '../MapControls';

TiledWebMap(Highcharts);
LayoutModule(Dashboards);
MarkerClusters(Highcharts);

Dashboards.HighchartsPlugin.custom.connectHighcharts(Highcharts);
Dashboards.DataGridPlugin.custom.connectDataGrid(DataGrid);

Dashboards.PluginHandler.addPlugin(Dashboards.HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(Dashboards.DataGridPlugin);

export interface EventExplorerDashboardFilters {
  startTimestamp: number;
  endTimestamp: number;
  organisationIds: string[];
  aircraft: string[];
  eventSubtypes: EventSubtype[];
  eventSeverities: EventSeverity[];
}

export interface EventsExplorerDashboardProps {
  filters: EventExplorerDashboardFilters;
  cancel: () => boolean;
}

const Dashboard: React.FC<EventsExplorerDashboardProps> = props => {
  const boardRef = useRef<Dashboards.Board | null>(null);
  const options = useDashboardOptions(props.filters, props.cancel);
  useEffect(() => {
    boardRef.current = Dashboards.board('events-explorer-dashboard', options);

    return () => {
      if (boardRef.current) {
        boardRef.current.destroy();
      }
    };
  }, [options]);

  return (
    <div>
      <div
        data-testid="events-explorer-dashboard"
        id="events-explorer-dashboard"
        className="highcharts-light"
      />
      <MapControls />
    </div>
  );
};

Dashboard.propTypes = {
  filters: PropTypes.shape({
    startTimestamp: PropTypes.number.isRequired,
    endTimestamp: PropTypes.number.isRequired,
    organisationIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    aircraft: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    eventSubtypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EventSubtype)).isRequired)
      .isRequired,
    eventSeverities: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EventSeverity)).isRequired)
      .isRequired
  }).isRequired,
  cancel: PropTypes.func.isRequired
};

export const EventsExplorerDashboard = memo(Dashboard);
