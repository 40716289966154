import { ServiceAbstract } from '../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';

export interface InvitedUser {
  id: string;
  email: string;
  invitedAt: number;
  expiresAt: number;
  expired: boolean;
  registered: boolean;
  inviteAccepted: boolean;
  invitedBy: string;
}

interface PhoneNumber {
  type: string;
  countryIdd: string;
  fullNumber: string;
  number: string;
}

export interface OrganisationMember {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  isPilot: boolean;
  spiderTxtEnabled: boolean;
  defaultAircraftAccess: boolean;
  organisationId: string;
  groups: { id: string; name: string }[];
  aircraft: { id: string; registration: string }[] | ['*'];
  phoneNumbers: PhoneNumber[];
}

export class UserService extends ServiceAbstract {
  public async getInvitedUsers(organisationId: string): Promise<InvitedUser[]> {
    const apiResponse = await Amplify.API.get(
        'api.spidertracks.io-authenticated',
        `organisations/${organisationId}/invites`
      );
      return apiResponse || [];
  }

  public async sendInvites(
    organisationId: string,
    emails: string[],
    message?: string
  ): Promise<void> {
    await Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/invites`,
      {
        body: {
          emails,
          ...(message && { "customMessage": message }),
        },
      });
    return;
  }

  public async cancelInvites(organisationId: string, inviteIds: string[]): Promise<void> {
    for (const id of inviteIds) {
      await Amplify.API.del(
        'api.spidertracks.io-authenticated',
        `organisations/${organisationId}/invites/${id}`
      );
    }
    return;
  }

  public async getOrganisationMembers(organisationId: string): Promise<OrganisationMember[]> {
    const apiResponse = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users`
    );
    return apiResponse.users || [];
  }

  public async assignAircraftAccess(
    userId: string,
    organisationId: string,
    vehicleIds: string[]
  ): Promise<void> {
    await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${userId}/accessible-aircraft`,
      {
        body: vehicleIds
      }
    );
  }

  private async deleteOrganisationMember(memberId: string, organisationId: string): Promise<void> {
    await Amplify.API.del(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${memberId}`
    );
  }

  public async deleteOrganisationMembers(
    memberIds: string[],
    organisationId: string
  ): Promise<void> {
    await Promise.all(
      memberIds.map(memberId => this.deleteOrganisationMember(memberId, organisationId))
    );
  }

  public async updateOrganisationMemberRole(
    organisationId: string,
    userId: string,
    role: string
  ): Promise<void> {
    await Amplify.API.patch(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${userId}`,
      {
        body: { role }
      }
    );
  }

  public async updateSpiderTxtStatus(
    organisationId: string,
    userId: string,
    textingEnabled: boolean
  ): Promise<void> {
    await Amplify.API.patch(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${userId}`,
      {
        body: { textingEnabled }
      }
    );
  }
}
