// Make sure that there is at least one default selected member for each parameter group. This is so that Highcharts requires at least one series in left axis.
import {
  convertMetresPerSecondTo,
  convertMetresTo,
  convertRadiansToDegrees
} from '../../../Flying/Map/utils/helper';
import { DisplayPoint } from '../../../../common/api/spidertracks-sdk/types/TrackData';
import { UserData } from '../../../../redux/types';
import { FlightDataSample } from '../../../../types/FlightData';

const DP = 2;

export interface Parameter {
  key: string;
  name: string;
  displayName: string;
  tooltip?: string;
  prerequisites?: {
    AHRS?: boolean;
    featureFlag?: string;
    flightDataSample?: (sample: FlightDataSample) => boolean;
  };
  accessors: {
    flightDataSample?: (sample: FlightDataSample, userData: UserData) => number;
    displayPoint?: (sample: DisplayPoint, userData: UserData) => number;
  };
}

export interface ParameterGroup {
  key: string;
  name: string;
  members: Parameter[];
  unit: (userData: UserData) => string;
  defaultSelectedMemberNames: string[];
  featureFlag?: string;
  axisDisplayString: (userData: UserData) => string;
}

export const AvailableChartParameters: ParameterGroup[] = [
  {
    key: 'altitude',
    name: 'Altitude',
    defaultSelectedMemberNames: ['AMSL'],
    axisDisplayString: (userData: UserData) => `Altitude (${userData.altitudeUnit})`,
    unit: (userData: UserData) => userData.altitudeUnit,
    members: [
      {
        key: 'amsl',
        name: 'AMSL',
        displayName: 'Altitude AMSL',
        tooltip: 'Height Above Mean Sea Level',
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresTo(sample.amslMetres, userData.altitudeUnit, DP)[0],
          displayPoint: (sample: DisplayPoint, userData: UserData) => {
            if (sample.altitudeUnit === 'm') {
              return convertMetresTo(sample.altitude, userData.altitudeUnit, DP)[0];
            } else {
              return parseFloat(sample.altitude.toFixed(DP));
            }
          }
        }
      },
      {
        key: 'agl',
        name: 'AGL',
        displayName: 'Altitude AGL',
        tooltip: 'Height Above Ground Level',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresTo(sample.aglMetres, userData.altitudeUnit, DP)[0]
        }
      },
      {
        key: 'terrain',
        name: 'Terrain',
        displayName: 'Terrain',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresTo(sample.elevationMetres, userData.altitudeUnit, DP)[0]
        }
      }
    ]
  },
  {
    key: 'speed',
    name: 'Speed',
    defaultSelectedMemberNames: ['Ground Speed'],
    axisDisplayString: (userData: UserData) => `Speed (${userData.speedUnit})`,
    unit: (userData: UserData) => userData.speedUnit,
    members: [
      {
        key: 'groundSpeed',
        name: 'Ground Speed',
        displayName: 'Ground Speed',
        tooltip: 'From GPS',
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresPerSecondTo(sample.groundSpeedMetresPerSecond, userData.speedUnit, DP),
          displayPoint: (sample: DisplayPoint, userData: UserData) => {
            if (sample.speed_raw !== undefined) {
              return convertMetresPerSecondTo(sample.speed_raw.value, userData.speedUnit, DP);
            }
            return convertMetresPerSecondTo(sample.speed, userData.speedUnit, DP);
          }
        }
      },
      {
        key: 'ias',
        name: 'Indicated Air Speed',
        displayName: 'Indicated Air Speed',
        tooltip: '',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.thirdPartyData?.indicatedAirSpeedMetresPerSecond === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresPerSecondTo(
              sample.thirdPartyData?.indicatedAirSpeedMetresPerSecond ?? 0,
              userData.speedUnit,
              DP
            )
        }
      }
    ]
  },
  {
    key: 'vertical_speed',
    name: 'Vertical Speed',
    defaultSelectedMemberNames: ['Vertical Speed'],
    axisDisplayString: (userData: UserData) => `Vertical Speed (ft/min)`,
    unit: (userData: UserData) => 'ft/min',
    members: [
      {
        key: 'verticalSpeed',
        name: 'Vertical Speed',
        displayName: 'Vertical Speed',
        tooltip: 'From GPS',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.velocity?.downMetresPerSecond === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            convertMetresPerSecondTo(sample.velocity.downMetresPerSecond * -1.0, 'ft/min', DP) // Make it so that up is +ve)
        }
      }
    ]
  },
  {
    key: 'attitude',
    name: 'Attitude',
    defaultSelectedMemberNames: ['Pitch'],
    axisDisplayString: (userData: UserData) => `Attitude (degrees)`,
    unit: (userData: UserData) => 'degrees',
    members: [
      {
        key: 'pitch',
        name: 'Pitch',
        displayName: 'Pitch',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) => convertRadiansToDegrees(sample.pitchRadians)
        }
      },
      {
        key: 'roll',
        name: 'Roll',
        displayName: 'Roll',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) => true
        },
        accessors: {
          flightDataSample: (sample, userData) => convertRadiansToDegrees(sample.rollRadians)
        }
      }
    ]
  },
  {
    key: 'forceG',
    name: 'Force',
    defaultSelectedMemberNames: ['Peak'],
    featureFlag: 'enable-peak-g',
    axisDisplayString: (userData: UserData) => `Force (g)`,
    unit: (userData: UserData) => 'g',
    members: [
      {
        key: 'peak',
        name: 'Peak',
        displayName: 'Peak',
        tooltip: 'From AHRS, units: g',
        prerequisites: {
          AHRS: true,
          flightDataSample: (sample: FlightDataSample) => !(sample.gForce === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) => parseFloat(sample.gForce.toFixed(DP))
        }
      }
    ]
  },
  {
    key: 'temperature',
    name: 'Temperature',
    defaultSelectedMemberNames: ['Engine 1 CHT1'],
    axisDisplayString: (userData: UserData) => `Temp (°C)`,
    unit: (userData: UserData) => '°C',
    members: [
      {
        key: 'engine1cht1',
        name: 'Engine 1 CHT1',
        displayName: 'Engine 1 CHT1',
        tooltip: 'Cylinder head temperature',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.thirdPartyData?.engine1Cht1DegreesC === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            parseFloat((sample.thirdPartyData?.engine1Cht1DegreesC ?? 0).toFixed(DP))
        }
      },
      {
        key: 'engine1egt1',
        name: 'Engine 1 EGT1',
        displayName: 'Engine 1 EGT1',
        tooltip: 'Exhaust gas temperature',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.thirdPartyData?.engine1Egt1DegreesC === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            parseFloat((sample.thirdPartyData?.engine1Egt1DegreesC ?? 0).toFixed(DP))
        }
      },
      {
        key: 'engine1oil',
        name: 'Engine 1 Oil',
        displayName: 'Engine 1 Oil',
        tooltip: 'Oil temperature',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.thirdPartyData?.engine1OilTDegreesC === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            parseFloat((sample.thirdPartyData?.engine1OilTDegreesC ?? 0).toFixed(DP))
        }
      }
    ]
  },
  {
    key: 'rotationalspeed',
    name: 'Rotational speed',
    defaultSelectedMemberNames: ['Engine 1 RPM'],
    axisDisplayString: (userData: UserData) => `Rotational speed (rpm)`,
    unit: (userData: UserData) => 'Rad/s',
    members: [
      {
        key: 'engine1rpm',
        name: 'Engine 1 RPM',
        displayName: 'Engine 1 RPM',
        tooltip: 'Engine 1 RPM',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.thirdPartyData?.engine1Rpm === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            parseFloat((sample.thirdPartyData?.engine1Rpm ?? 0).toFixed(DP))
        }
      }
    ]
  },
  {
    key: 'pressure',
    name: 'Pressure',
    defaultSelectedMemberNames: ['Engine 1 MAP'],
    axisDisplayString: (userData: UserData) => `pa`,
    unit: (userData: UserData) => 'pa',
    members: [
      {
        key: 'engine1map',
        name: 'Engine 1 MAP',
        displayName: 'Engine 1 MAP',
        tooltip: 'Inlet manifold air pressure',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.thirdPartyData?.engine1MapPa === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            parseFloat((sample.thirdPartyData?.engine1MapPa ?? 0).toFixed(DP))
        }
      },
      {
        key: 'engine1oil',
        name: 'Engine 1 oil',
        displayName: 'Engine 1 oil',
        tooltip: 'Engine 1 oil pressure',
        prerequisites: {
          flightDataSample: (sample: FlightDataSample) =>
            !(sample.thirdPartyData?.engine1OilPPa === undefined)
        },
        accessors: {
          flightDataSample: (sample, userData) =>
            parseFloat((sample.thirdPartyData?.engine1OilPPa ?? 0).toFixed(DP))
        }
      }
    ]
  }
];
