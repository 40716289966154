import { FullState } from '../../store';
import { createSelector } from 'reselect';
import { InsightRulesState, OrganisationInsightRules } from '../../types/insightRules';
import { EventParameterItem } from '../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { UserData } from '../types';

export const getInsightRules = (state: FullState): InsightRulesState => state.insightRules;

export const getOrganisationInsightRules = createSelector(
  [getInsightRules],
  (state: InsightRulesState): OrganisationInsightRules => state.organisationRules
);

export const getInsightEventParameters = createSelector(
  [getInsightRules],
  (state: InsightRulesState): EventParameterItem[] => state.eventParameters
);

export const getInsightUserData = createSelector(
  [getInsightRules],
  (state: InsightRulesState): UserData => state.userData
);
