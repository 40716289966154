import { CompleteFlightDataServiceResponse } from '../../../../../hooks/useFlightDataService/flightDataStore';
import { ServiceAbstract } from '../../ServiceAbstract';
import {
  Ahrs,
  FlightData,
  FlightDataApiResponse,
  FlightDataStatus,
  Position
} from '../../types/FlightData';
import { Amplify } from '@spidertracks/common';

interface FlightDataQueryParams {
  trackId: number;
  fromEpochMillisIncluding?: number;
  toEpochMillisExcluding?: number;
  sources?: string;
  raw3dfr?: boolean;
}
interface FlightDataStatusQueryParams {
  trackIds: string;
  sources?: string;
}

export class FlightData3DFRService extends ServiceAbstract {
  public async getFlightData(
    trackId: number,
    orgId: string,
    /** A comma seperated list or single value of these items: spider,platform */
    allowedSources: string,
    from?: number,
    to?: number
  ): Promise<FlightData> {
    let queryStringParameters: FlightDataQueryParams = { trackId };
    if (from && to) {
      queryStringParameters.fromEpochMillisIncluding = from;
      queryStringParameters.toEpochMillisExcluding = to;
    }

    queryStringParameters.sources = allowedSources;
    const response = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `internal/organisations/${orgId}/get-3dfr-flight-data`,
      {
        queryStringParameters
      }
    );

    return this.convertFlightDataApiResponseToFlightData(response);
  }

  public async getCompleteFlightData(
    trackId: number,
    orgId: string,
    /** A comma seperated list or single value of these items: spider,platform */
    allowedSources: string,
    from?: number,
    to?: number
  ): Promise<CompleteFlightDataServiceResponse> {
    let queryStringParameters: FlightDataQueryParams = { trackId };
    if (from && to) {
      queryStringParameters.fromEpochMillisIncluding = from;
      queryStringParameters.toEpochMillisExcluding = to;
    }

    if (allowedSources != '') {
      queryStringParameters.sources = allowedSources;
    }
    const response = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `internal/organisations/${orgId}/get-3dfr-flight-data`,
      {
        queryStringParameters
      }
    );

    return {
      session: response.session,
      raw: response.samples,
      smoothed: response.smoothedSamples
    };
  }

  public async getFlightDataStatus(
    trackIds: number[],
    allowedSources: string = 'platform'
  ): Promise<FlightDataStatus[]> {
    let queryStringParameters: FlightDataStatusQueryParams = {
      trackIds: trackIds.join(','),
      sources: allowedSources
    };
    return await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `internal/get-3dfr-flight-data-status`,
      {
        queryStringParameters
      }
    );
  }

  public convertFlightDataApiResponseToFlightData(
    flightDataApiResponse: FlightDataApiResponse
  ): FlightData {
    const trackStartTime = Math.round(flightDataApiResponse.session.startTimeEpochMillis / 1000);
    const trackEndTime = Math.round(flightDataApiResponse.session.endTimeEpochMillis / 1000);
    const sortedSamples = flightDataApiResponse.samples.sort(
      (a, b) => a.sampleTimeEpochMillis - b.sampleTimeEpochMillis
    );
    const sortedSmoothedSamples = flightDataApiResponse.smoothedSamples.sort(
      (a, b) => a.sampleTimeEpochMillis - b.sampleTimeEpochMillis
    );
    const smoothing = !Boolean(new URLSearchParams(window.location.search).get('raw3dfr'));
    const from = Math.round(flightDataApiResponse.window.fromEpochMillisIncluding / 1000);
    const to = Math.round(flightDataApiResponse.window.toEpochMillisExcluding / 1000);

    const position: Position[] = [];
    const ahrs: Ahrs[] = [];

    if (smoothing) {
      sortedSmoothedSamples.forEach(sample => {
        const timestampSec = Math.round(sample.sampleTimeEpochMillis / 1000);
        position.push({
          timestamp: timestampSec,
          latitude: sample.latitude,
          longitude: sample.longitude,
          altitude: sample.amslMetres,
          aglMetres: sample.aglMetres
        });
        ahrs.push({
          timestamp: timestampSec,
          roll: sample.rollRadians,
          pitch: sample.pitchRadians,
          yaw: sample.yawRadians
        });
      });
    } else {
      sortedSamples.forEach(sample => {
        const timestampSec = Math.round(sample.sampleTimeEpochMillis / 1000);
        position.push({
          timestamp: timestampSec,
          latitude: sample.latitude,
          longitude: sample.longitude,
          altitude: sample.amslMetres
        });
        ahrs.push({
          timestamp: timestampSec,
          roll: sample.rollRadians,
          pitch: sample.pitchRadians,
          yaw: sample.yawRadians
        });
      });
    }

    const flightData: FlightData = {
      trackStartTime,
      trackEndTime,
      from,
      to,
      position,
      ahrs,
      source: flightDataApiResponse.session.source,
      smoothing
    };

    if (to < trackEndTime) {
      flightData.nextToken = {
        position: flightDataApiResponse.window.toEpochMillisExcluding,
        ahrs: flightDataApiResponse.window.toEpochMillisExcluding
      };
    }

    return flightData;
  }
}

export default FlightData3DFRService;
