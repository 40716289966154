import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Geojson } from '../../../common/api/spidertracks-sdk/types/GeoJSON';
import { getActiveKmls } from '../../../redux/selectors/mapData';
import { getInstance } from '../../../common/api/spidertracks-sdk';

export const useGeofences = (): Geojson | undefined => {
  const [geofences, setGeofences] = useState<Geojson>();

  // Layers are implemented using kmls (and geofence special case!)
  const activeLayers = useSelector(getActiveKmls);

  const SpidertracksSDK = getInstance();

  useEffect(() => {
    const loadGeofences = async () => {
      if (activeLayers.find(layer => layer === 'geofence')) {
        const fences = await SpidertracksSDK.getGeofencesService().getGeofences();
        setGeofences(fences);
      }
    };
    loadGeofences();
  }, [activeLayers]);

  return geofences;
};
