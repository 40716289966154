import { MultiSelectForm, GroupSelectionOption } from '@spidertracks/components';
import { Form, Row } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, ReactNode, useEffect } from 'react';
import { AircraftDetails } from '../../../types/aircraft';
import { getAircraftRegistrationLabel } from '@spidertracks/components';

interface AircraftSelectorProps extends FormComponentProps {
  initialValue: CheckboxValueType[];
  isEditForm: boolean;
  showAllChoice?: boolean;
  showRotaryChoice?: boolean;
  showFixedWingChoice?: boolean;
  aircraft?: AircraftDetails[];
  aircraftOptionTransformer?: (a: AircraftDetails) => CheckboxOptionType;
  options?: CheckboxOptionType[]; // Probably should deprecate the use of this
  label?: ReactNode;
  allowNoneSelection?: boolean;
}

export const AircraftSelector: FC<AircraftSelectorProps> = ({
  form,
  initialValue,
  aircraft,
  aircraftOptionTransformer,
  options,
  showAllChoice,
  showFixedWingChoice,
  showRotaryChoice,
  label = 'Applies to',
  allowNoneSelection = false
}) => {
  const onReset = () => form.setFieldsValue({ aircraft: initialValue });

  useEffect(() => {
    form.resetFields();
  }, [initialValue.length]);

  if (aircraft && aircraftOptionTransformer) {
    options = aircraft.map(aircraftOptionTransformer);
  }

  const groups: GroupSelectionOption[] = [];
  let showAll = false;
  if (aircraft) {
    const cons: [string, AircraftDetails][] = aircraft.map(value => {
      return [value.id, value];
    });
    const aircraftById = new Map<string, AircraftDetails>([...cons]);
    if (showAllChoice) {
      groups.push({
        label: 'All aircraft',
        id: '*',
        onSelectFilter: (value: CheckboxValueType) => true,
        isSelectAll: true
      });
    }
    if (showFixedWingChoice) {
      groups.push({
        label: 'All fixed wing',
        id: 'FIXED_WING',
        onSelectFilter: (value: CheckboxValueType) =>
          aircraftById.get(value as string)?.type == 'FIXED_WING'
      });
    }
    if (showRotaryChoice) {
      groups.push({
        label: 'All rotary wing',
        id: 'ROTARY_WING',
        onSelectFilter: (value: CheckboxValueType) =>
          aircraftById.get(value as string)?.type == 'ROTARY_WING'
      });
    }
  } else {
    showAll = true;
  }

  return (
    <Form.Item label={label} style={{ textAlign: 'left' }} className="mt-3">
      <Row>
        {form.getFieldDecorator('aircraft', {
          initialValue,
          rules: !allowNoneSelection
            ? [
                {
                  required: true,
                  message: 'Please select aircraft'
                }
              ]
            : []
        })(
          <MultiSelectForm
            closedLabel={getAircraftRegistrationLabel(
              form.getFieldValue('aircraft'),
              'Select aircraft'
            )}
            fieldName="aircraft"
            form={form}
            options={options || []}
            onReset={onReset}
            showSearch
            shifted={true}
            groups={groups}
            showAll={showAll}
          />
        )}
      </Row>
    </Form.Item>
  );
};

export default AircraftSelector;
