import React from 'react';
import { getContactMap } from '../../redux/selectors/spiderTxt';
import { useSelector } from 'react-redux';
import { ConversationList } from '@chatscope/chat-ui-kit-react';

import ContactListItem from './contactListItem';

const ContactList: React.FC = () => {
  const contactMap = useSelector(getContactMap);

  return (
    <ConversationList>
      {Array.from(contactMap.values()).map(contact => (
        <ContactListItem key={contact.id} contact={contact} />
      ))}
    </ConversationList>
  );
};

export default ContactList;
