import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import '../../Flying/Map/MapControls/mapview/styles/scss/mapviewoptions.scss';
import { availableMaps } from './AvailableMaps';

interface ComponentProps {
  initialView: string;
  onChange: ({ key, value }: { key: string; value: string }) => void;
}

export const MapViewOptions = (props: ComponentProps) => {
  const onChange = (e: RadioChangeEvent) => {
    const selectedOption = availableMaps.find(option => option.value === e.target.value);
    if (selectedOption) {
      props.onChange({ key: selectedOption.key, value: selectedOption.value });
    }
  };

  return (
    <span role="button" tabIndex={0}>
      <Radio.Group
        data-testid="events-explorer-mapview-selector"
        options={availableMaps}
        defaultValue={props.initialView}
        onChange={onChange}
        className="mapview-radio"
      >
        {availableMaps.map(option => (
          <Radio key={option.key} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </span>
  );
};
