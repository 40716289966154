import { GroundEvent, TakeoffLanding } from './lockToGroundTypes';
import { FlightDataSample } from '../../../types/FlightData';

interface LockAirCraftToGroundEventsOptions {
  flightData: FlightDataSample[];
  terrainAltitudes: number[];
  groundEvents: GroundEvent[];
}

export function lockAircraftToGround(
  options: LockAirCraftToGroundEventsOptions
): FlightDataSample[] {
  const { flightData, terrainAltitudes, groundEvents } = options;
  if (flightData.length !== terrainAltitudes.length) {
    throw new Error('GPS samples and terrain altitudes must be the same length');
  }
  let onGround = true;

  const handleGroundEvent = (event: GroundEvent | undefined): boolean => {
    if (event) {
      if (event.type !== TakeoffLanding.TouchAndGo) {
        onGround = event.type === TakeoffLanding.Landing;
        return false;
      }
      return true;
    }
    return false;
  };

  while (
    groundEvents.length > 0 &&
    flightData.length > 0 &&
    groundEvents[0].timestamp < flightData[0].sampleTimeEpochMillis
  ) {
    handleGroundEvent(groundEvents.shift());
  }

  let lockedToGroundSamples = flightData.reduce((acc, sample, index) => {
    let touchAndGo = false;
    if (groundEvents.length > 0 && groundEvents[0].timestamp === sample.sampleTimeEpochMillis) {
      touchAndGo = handleGroundEvent(groundEvents.shift());
    }

    if (onGround || touchAndGo) {
      acc.push({
        ...sample,
        amslMetres: terrainAltitudes[index]
      });
    } else {
      acc.push({ ...sample });
    }
    return acc;
  }, [] as FlightDataSample[]);

  return lockedToGroundSamples;
}
