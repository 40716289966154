import { EventParameterItem } from '../common/api/spidertracks-sdk/private/services/EventRulesService';
import {
  InsightRuleConditionSource,
  InsightRuleConditionType,
  InsightRuleEntity,
  InsightRuleThreshold
} from '../common/api/spidertracks-sdk/private/services/InsightRulesService';
import { UserData } from '../redux/types';

export enum EventRuleType {
  EXCESSIVE_PITCH_DOWN = 'excessive_pitch_down',
  EXCESSIVE_PITCH_DOWN_AT_LOW_ALTITUDE_AGL = 'excessive_pitch_down_at_low_altitude_agl',
  EXCESSIVE_PITCH_UP = 'excessive_pitch_up',
  EXCESSIVE_PITCH_UP_AT_LOW_SPEED = 'excessive_pitch_up_at_low_speed',
  EXCESSIVE_PITCH_UP_AT_LOW_ALTITUDE_AGL = 'excessive_pitch_up_at_low_altitude_agl',
  EXCESSIVE_RATE_OF_CLIMB = 'excessive_rate_of_climb',
  EXCESSIVE_ROLL = 'excessive_roll',
  EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AMSL = 'excessive_roll_at_low_altitude',
  EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AGL = 'excessive_roll_at_low_altitude_agl',
  HIGH_RATE_OF_DESCENT = 'high_rate_of_descent',
  HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AMSL = 'high_rate_of_descent_at_low_altitude',
  HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AGL = 'high_rate_of_descent_at_low_altitude_agl',
  MAXIMUM_ALTITUDE_AGL = 'maximum_altitude_agl',
  MAXIMUM_ALTITUDE_AMSL = 'maximum_altitude_amsl',
  EXCESSIVE_G_FORCE = 'excessive_g_force',
  EXCESSIVE_TEMPERATURE = 'excessive_temperature',
  EXCESSIVE_RPM = 'excessive_rpm',
  EXCESSIVE_SPEED = 'excessive_speed'
}

export interface DisplayInsightRuleThreshold extends InsightRuleThreshold {
  thresholdUnit: string;
}

export interface DisplayInsightRuleCondition {
  type: InsightRuleConditionType;
  source: InsightRuleConditionSource;
  displayThreshold: DisplayInsightRuleThreshold;
}

export interface InsightRule
  extends Omit<InsightRuleEntity, 'primaryCondition' | 'secondaryCondition'> {
  primaryCondition: DisplayInsightRuleCondition;
  secondaryCondition: DisplayInsightRuleCondition | undefined;
}

export interface InsightRuleToCreate extends Omit<InsightRule, 'id'> {}

export interface OrganisationInsightRules {
  /** orgId -> rules */
  [key: string]: InsightRule[];
}

export interface InsightRulesState {
  /** Map of orgId -> rules */
  organisationRules: OrganisationInsightRules;
  eventParameters: EventParameterItem[];
  userData: UserData;
}
