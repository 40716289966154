import React from 'react';
import { getActiveContactId } from '../../redux/selectors/spiderTxt';
import { setActiveContactId } from '../../redux/slice/spiderTxt';

import { UserContact, GroupContact, Message, ContactType } from '../../redux/types';
import { Avatar, Conversation } from '@chatscope/chat-ui-kit-react';
import { useDispatch, useSelector } from 'react-redux';

interface ContactListItemProps {
  contact: UserContact | GroupContact;
}

const ContactListItem: React.FC<ContactListItemProps> = ({ contact }) => {
  const dispatch = useDispatch();
  const activeConversationId = useSelector(getActiveContactId);

  const { id: contactId, conversation, displayName } = contact;
  const { messages, unreadMessageCount } = conversation;
  let lastMessage: Message | undefined;
  if (messages.length > 0) {
    lastMessage = messages[0];
  }
  return (
    <Conversation
      info={lastMessage ? lastMessage.message : undefined}
      lastSenderName={lastMessage ? lastMessage.senderDisplayName : undefined}
      name={displayName}
      unreadCnt={unreadMessageCount}
      lastActivityTime={lastMessage ? new Date(lastMessage.timestamp).toISOString() : undefined}
      onClick={() => {
        dispatch(setActiveContactId({ activeContactId: contactId }));
      }}
      active={activeConversationId === contactId}
    >
      <Avatar
        name={displayName}
        src={
          contact.contactType == ContactType.INDIVIDUAL
            ? (contact as UserContact).avatarUrl
            : undefined
        }
      />
    </Conversation>
  );
};

export default ContactListItem;
