import React, { useCallback, useRef, useState, useMemo } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import AircraftCell from './AircraftCell';
import { getRowClassName } from '../../common';
import { EventsConfigTableHeader } from './EventsConfigTableHeader';
import { getFilterDropdown } from '../../common/filters/getFilterDropdown';
import { eventRuleDeleteSuccess } from '../../../helpers';
import EventsConfigDrawer from './EventsConfigDrawer';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import {
  getDescriptionForInsightRule,
  getEventRuleTypeFromConditions,
  sortDescriptionsByAZ,
  sortRuleNamesByAZ
} from './utils';
import { useParams } from 'react-router-dom';
import { eventRuleUpdateSuccess, eventRuleUpdateError } from '../../../helpers';
import {
  getInsightEventParameters,
  getOrganisationInsightRules
} from '../../../redux/selectors/insightRules';
import { TableFullRowSelect } from '@spidertracks/components';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { useDispatch, useSelector } from 'react-redux';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';
import { InsightRule } from '../../../types/insightRules';
import {
  EventParameterItem,
  EventType
} from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { deleteInsightRules } from '../../../redux/slice/insightRules';

export const aircraftOnFilter = (value: any, record: any) =>
  record.allAircraft || (record.aircraftIds && record.aircraftIds.includes(value));

export interface TableRow extends InsightRule {
  description: string;
  parameters: EventParameterItem[];
}

export const EventsConfigTable = (props: {
  aircraft: PublicAircraftData[];
  isLoading: boolean;
  eventTypes: EventType[];
  refresh: () => Promise<void>;
  insightsSafetyEnabled: boolean;
}) => {
  const { aircraft, isLoading, eventTypes, refresh, insightsSafetyEnabled } = props;
  const ref = useRef(() => {});
  const { organisationId } = useParams<{ organisationId: string }>();
  const allOrganisationRules = useSelector(getOrganisationInsightRules);
  const allEventParametersItems = useSelector(getInsightEventParameters);
  const dispatch = useDispatch();

  const organisationRules = allOrganisationRules[organisationId] || [];
  const table: TableRow[] = useMemo(() => {
    const rows: TableRow[] = organisationRules.map(rule => {
      const description = getDescriptionForInsightRule(rule);
      return {
        ...rule,
        description: description,
        parameters: allEventParametersItems
          .filter(el => {
            if (allEventParametersItems.length == 0) {
              return [];
            }
            return el.eventType == getEventRuleTypeFromConditions(rule);
          })
          .map(el => {
            return {
              ...el,
              parameters: el.parameters.map(p => {
                const threshold =
                  p.name == rule.primaryCondition.type
                    ? rule.primaryCondition.displayThreshold
                    : rule.secondaryCondition!.displayThreshold;
                return {
                  ...p,
                  displayUnit: threshold.thresholdUnit,
                  displayValue: threshold.severityEnabled
                    ? {
                        low: threshold.thresholdValueLow,
                        medium: threshold.thresholdValueMedium,
                        high: threshold.thresholdValueHigh
                      }
                    : threshold.thresholdValue
                };
              })
            };
          })
      };
    });

    rows.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    return rows;
  }, [organisationRules]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedEventRule, setSelectedEventRule] = useState<TableRow>();
  const [visibility, setVisibility] = useState(false);
  const [selectedAircraft, setAircraftFilters] = useState([]);

  const onOpen = () => setVisibility(true);

  const onClose = () => {
    setVisibility(false);
    setSelectedEventRule(undefined);
  };

  const aircraftFilters = aircraft.map(a => ({
    key: a.id,
    value: a.id,
    label: a.registration
  }));

  const allAircraftRegistrations = useMemo(
    () => aircraft.reduce((acc, a) => ({ ...acc, [a.id]: a.registration }), {}),
    [aircraft]
  );

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: `rule_name`,
      sorter: sortRuleNamesByAZ
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      sorter: sortDescriptionsByAZ,
      render: (text: any, record: TableRow) => {
        if (record.primaryCondition.displayThreshold.severityEnabled) {
          return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
        }
        return text;
      }
    },
    {
      title: 'AIRCRAFT',
      dataIndex: 'aircraftIds',
      key: `aircraftIds${aircraftFilters.length}`,
      filters: aircraftFilters,
      onFilter: aircraftOnFilter,
      ...getFilterDropdown({
        displayName: 'aircraft',
        onFilter: setAircraftFilters,
        clearSelection: () => [],
        showSearch: true,
        style: {}
      })(),
      filteredValue: selectedAircraft,
      render: function AircraftTableCell(aircraftIds = [], row: any) {
        return (
          <AircraftCell
            aircraftRegistrations={aircraftIds.map(id => allAircraftRegistrations[id]).sort()}
            row={row}
          />
        );
      }
    }
  ];

  const handleTableChange = () => {
    ref.current();
  };

  const deleteEventRule = useCallback(async () => {
    const toDelete = selectedRowKeys.map(id => {
      return { organisationId, ruleId: id };
    });
    dispatch(deleteInsightRules(toDelete, eventRuleDeleteSuccess));

    setSelectedRowKeys([]);
    setSelectedEventRule(undefined);
  }, [refresh, organisationId, selectedRowKeys]);

  const keys = new Set(selectedRowKeys);
  const selectedEventRules = table.filter(row => keys.has(row.id));

  const rowConfig = (row: TableRow) => ({
    onClick: (e: Event) => {
      e.preventDefault();
      if (row == undefined) {
        return;
      }
      setSelectedEventRule(row);
      onOpen();
    }
  });

  const onChange = (selectedRowKeys: string[]) => setSelectedRowKeys(selectedRowKeys);
  const onSelectAll = (selected: any) => !selected && setSelectedRowKeys([]);
  const rowClassName = (record: any) =>
    selectedEventRule && record.id === selectedEventRule.id
      ? `${getRowClassName()} sts-row-clicked`
      : getRowClassName();

  const deleteMessage =
    selectedEventRules.length === 1
      ? `Are you sure you want to delete the event ${selectedEventRules[0].name}?`
      : `Are you sure you want to delete these ${selectedEventRules.length} events?`;

  const onDeleteClick = useCallback(
    () =>
      Modal.confirm({
        title: 'Delete Event',
        icon: <ExclamationCircleFilled />,
        content: deleteMessage,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: deleteEventRule
      }),
    [deleteMessage, deleteEventRule]
  );

  const updateEventRule = async (eventRule: InsightRule) => {
    if (selectedEventRule == undefined) {
      return;
    }
    try {
      await getInstance()
        .getEventRulesService()
        .updateEventRule(organisationId, selectedEventRule.id, eventRule);
      eventRuleUpdateSuccess();
    } catch (e) {
      console.log(e);
      eventRuleUpdateError();
    } finally {
      refresh();
    }
  };

  return (
    <React.Fragment>
      <EventsConfigTableHeader
        selectedEventRules={selectedEventRules as any}
        onDeleteClick={onDeleteClick}
      />
      <TableFullRowSelect
        className="event-rules-table"
        pagination={{ pageSize: 30 }}
        rowSelection={{
          selectedRowKeys,
          onChange,
          onSelectAll
        }}
        rowKey="id"
        size="middle"
        bordered
        dataSource={table}
        columns={columns}
        loading={isLoading}
        rowClassName={rowClassName}
        onChange={handleTableChange}
        onRow={rowConfig}
      />
      {visibility && (
        <EventsConfigDrawer
          aircraft={aircraft}
          isEditForm={true}
          visibility={visibility}
          onClose={onClose}
          selectedEventRule={selectedEventRule!}
          // setSelectedEventRule={setSelectedEventRule}
          // eventParameters={eventParameters}
          eventTypes={eventTypes}
          save={updateEventRule}
          // organisationIds={[organisationId]}
          insightsSafetyEnabled={insightsSafetyEnabled}
        />
      )}
    </React.Fragment>
  );
};

EventsConfigTable.propTypes = {
  aircraft: PropTypes.array,
  tableData: PropTypes.array,
  isLoading: PropTypes.bool,
  eventTypes: PropTypes.array,
  refresh: PropTypes.func,
  insightsSafetyEnabled: PropTypes.bool
};

export default EventsConfigTable;
