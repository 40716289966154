import { createSlice } from '@reduxjs/toolkit';
import {
  SpiderTxtState,
  ContactType,
  MessageType,
  MessageStatus,
  UserContact,
  GroupContact
} from '../types';

const demoContactMap = new Map<string, UserContact | GroupContact>();
demoContactMap.set('contact-1', {
  id: 'contact-1',
  displayName: 'Lilly',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [
      {
        id: 'message-1',
        message: 'Yes i can do it for you',
        senderId: 'contact-0',
        senderDisplayName: 'My Name',
        contactId: 'contact-0',
        timestamp: 1737652296261,
        type: MessageType.SENT,
        status: MessageStatus.DELIVERED
      }
    ],
    unreadMessageCount: 88,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/lilly-aj6lnGPk.svg'
});
demoContactMap.set('contact-2', {
  id: 'contact-2',
  displayName: 'Joe',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [
      {
        id: 'message-1',
        message: 'Yes i can do it for you',
        senderId: 'contact-2',
        senderDisplayName: 'Joe',
        contactId: 'contact-2',
        timestamp: 1737652296261,
        type: MessageType.RECEIVED,
        status: MessageStatus.DELIVERED
      }
    ],
    unreadMessageCount: 0,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/joe-v8Vy3KOS.svg'
});
demoContactMap.set('contact-3', {
  id: 'contact-3',
  displayName: 'Emily',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [
      {
        id: 'message-1',
        message: 'Yes i can do it for you',
        senderId: 'contact-3',
        senderDisplayName: 'Emily',
        contactId: 'contact-3',
        timestamp: 1737652296261,
        type: MessageType.RECEIVED,
        status: MessageStatus.DELIVERED
      }
    ],
    unreadMessageCount: 6,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg'
});
demoContactMap.set('contact-4', {
  id: 'contact-4',
  displayName: 'Kai',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [
      {
        id: 'message-1',
        message: 'Yes i can do it for you',
        senderId: 'contact-4',
        senderDisplayName: 'Kai',
        contactId: 'contact-4',
        timestamp: 1737652296261,
        type: MessageType.RECEIVED,
        status: MessageStatus.DELIVERED
      }
    ],
    unreadMessageCount: 5,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/kai-5wHRJGb2.svg'
});

demoContactMap.set('contact-5', {
  id: 'contact-5',
  displayName: 'Akane',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [
      {
        id: 'message-1',
        message: 'Yes i can do it for you',
        senderId: 'contact-5',
        senderDisplayName: 'Akane',
        contactId: 'contact-5',
        timestamp: 1620000000000,
        type: MessageType.RECEIVED,
        status: MessageStatus.DELIVERED
      }
    ],
    unreadMessageCount: 10,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/akane-MXhWvx63.svg'
});
demoContactMap.set('contact-6', {
  id: 'contact-6',
  displayName: 'Eliot',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [
      {
        id: 'message-1',
        message: 'Yes i can do it for you',
        senderId: 'contact-6',
        senderDisplayName: 'Eliot',
        contactId: 'contact-6',
        timestamp: 1620000000000,
        type: MessageType.RECEIVED,
        status: MessageStatus.DELIVERED
      }
    ],
    unreadMessageCount: 0,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/eliot-JNkqSAth.svg'
});
demoContactMap.set('contact-7', {
  id: 'contact-7',
  displayName: 'Zoe',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [
      {
        id: 'message-1',
        message: 'Yes i can do it for you',
        senderId: 'contact-7',
        senderDisplayName: 'Zoe',
        contactId: 'contact-7',
        timestamp: 1620000000000,
        type: MessageType.RECEIVED,
        status: MessageStatus.DELIVERED
      }
    ],
    unreadMessageCount: 0,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg'
});
demoContactMap.set('contact-8', {
  id: 'contact-8',
  displayName: 'Patrik',
  contactType: ContactType.INDIVIDUAL,
  conversation: {
    messages: [],
    unreadMessageCount: 0,
    draftMessageText: undefined
  },
  avatarUrl: 'https://chatscope.io/storybook/react/assets/patrik-yC7svbAR.svg'
});

const initialState: SpiderTxtState = {
  activeContactId: undefined,
  contactMap: demoContactMap,
  contactFilterText: '',
  unreadMessageCount: 0
};

export const spiderTxtSlice = createSlice({
  name: 'spiderTxt',
  initialState,
  reducers: {
    setActiveContactId: (state, { payload }) => {
      state.activeContactId = payload.activeContactId;
    },
    reset: state => {
      state.activeContactId = undefined;
      state.contactMap = new Map<string, UserContact | GroupContact>();
      state.contactFilterText = '';
      state.unreadMessageCount = 0;
    }
  }
});

export const { setActiveContactId } = spiderTxtSlice.actions;

export default spiderTxtSlice;
