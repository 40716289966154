import React, { FC } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { User } from '../../common/form/UserSelector';
import { AircraftDetails } from '../../../types/aircraft';
import { useLocation } from 'react-router';
import { GeofenceNotificationForm } from './GeofenceNotificationForm';
import { ExceedanceByTypeNotificationForm } from './ExceedanceByTypeNotificationForm';
import { EventRule } from './EventRuleSelector';
import { InsightRule } from '../../../types/insightRules';

export interface NotificationConfiguration {
  id?: string;
  users: string[];
  aircraft: string[];
  notifyMethod: string[];
  eventClass: string;
  eventRules: string[];
  eventRuleIds: string[] | undefined;
  /** Array of LOW, MEDIUM, HIGH, NOT_SPECIFIED */
  excludedSeverities: string[];
}

export const checkboxUserOptionTransformer = (
  disabled = false,
  valueTransformer: (user: User) => string = user => user.id
) => (user: User): CheckboxOptionType => ({
  disabled,
  label: user.name,
  value: valueTransformer(user)
});

export const eventRuleCheckboxRuleOptionTransformer = (
  disabled = false,
  valueTransformer: (rule: EventRule) => string = rule => rule.id
) => (rule: EventRule): CheckboxOptionType => ({
  disabled,
  label: rule.label,
  value: valueTransformer(rule)
});

export const insightRuleCheckboxRuleOptionTransformer = (
  disabled = false,
  valueTransformer: (rule: InsightRule) => string = rule => rule.id
) => (rule: InsightRule): CheckboxOptionType => ({
  disabled,
  label: rule.name,
  value: valueTransformer(rule)
});

export interface NotificationFormProps extends FormComponentProps {
  initialData: NotificationConfiguration | null;
  aircraft: AircraftDetails[];
  onClose: () => void;
  createNotification: (config: NotificationConfiguration) => Promise<void>;
  updateNotification: (config: NotificationConfiguration) => Promise<void>;
  deleteNotification: (notificationConfigurationId: string) => Promise<void>;
  users: User[];
  geofenceEventRules: EventRule[];
  exceedenceEventRules: InsightRule[];
}

export const NotificationForm: FC<NotificationFormProps> = ({
  initialData,
  aircraft,
  onClose,
  createNotification,
  updateNotification,
  deleteNotification,
  users,
  form,
  geofenceEventRules,
  exceedenceEventRules
}) => {
  const location = useLocation();
  const { pathname } = location;
  const currentActiveTab = pathname.slice(pathname.lastIndexOf('/') + 1);

  switch (currentActiveTab) {
    case 'geofences':
      return (
        <GeofenceNotificationForm
          initialData={initialData}
          form={form}
          users={users}
          aircraft={aircraft}
          eventRules={geofenceEventRules}
          createNotification={createNotification}
          updateNotification={updateNotification}
          deleteNotification={deleteNotification}
          closeDrawer={onClose}
        />
      );
    case 'exceedances':
      return (
        <ExceedanceByTypeNotificationForm
          initialData={initialData}
          form={form}
          users={users}
          eventRules={exceedenceEventRules}
          createNotification={createNotification}
          updateNotification={updateNotification}
          deleteNotification={deleteNotification}
          closeDrawer={onClose}
        />
      );
    default:
      return <div></div>;
  }
};
