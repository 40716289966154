import * as airac from 'airac-cc';

const { identifier } = airac.Cycle.fromDate(new Date());

export const availableMaps = [
  {
    key: 'MAP',
    label: 'Map',
    value: 'roadmap',
    provider: 'ESRI',
    theme: 'WorldStreetMap',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 21
  },
  {
    key: 'SATELLITE',
    label: 'Satellite',
    value: 'satellite',
    provider: 'ESRI',
    theme: 'WorldImagery',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 21
  },
  {
    key: 'TERRAIN',
    label: 'Terrain',
    value: 'terrain',
    provider: 'ESRI',
    theme: 'WorldTopoMap',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 21
  },
  {
    key: 'HYBRID',
    label: 'Hybrid',
    value: 'hybrid',
    provider: 'ESRI',
    theme: 'NatGeoWorldMap',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 21
  },
  {
    key: 'SECTIONAL',
    label: 'VFR View',
    value: 'vfr',
    provider: 'SkyVector',
    theme: 'SECTIONAL',
    url: `https://t.skyvector.com/68b6ba18/vfr/${identifier}/{z}/{x}/{y}.jpg`,
    maxZoom: 11
  },
  {
    key: 'LOW',
    label: 'IFR Low',
    value: 'lo',
    provider: 'SkyVector',
    theme: 'LOW',
    url: `https://t.skyvector.com/68b6ba18/lo/${identifier}/{z}/{x}/{y}.jpg`,
    maxZoom: 10
  },
  {
    key: 'HIGH',
    label: 'IFR High',
    value: 'hi',
    provider: 'SkyVector',
    theme: 'HIGH',
    url: `https://t.skyvector.com/68b6ba18/hi/${identifier}/{z}/{x}/{y}.jpg`,
    maxZoom: 9
  },
  {
    key: 'BASIC',
    label: 'Basic',
    value: 'basic',
    provider: 'ESRI',
    theme: 'WorldGrayCanvas',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 21
  }
];
