/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Row, Button, Spin, Tooltip, Alert } from 'antd';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

import { getInstance } from '../../../common/api/spidertracks-sdk';
import { UserAltitudeUnit } from '../../../common/api/spidertracks-sdk/types/UserData';
import {
  SpiderType,
  SpiderUpdatingType
} from '../../../common/api/spidertracks-sdk/types/SpiderSettings';
import { getSpeedUnit, getAltitudeUnit } from '../../../redux/selectors/userData';
import {
  formatSpeedFromMetersPerSeconds,
  formatSpeedToMetersPerSeconds,
  roundToTenth
} from '../../../helpers/formatSpeed';
import SpiderSettingsInput from './SpiderSettingsInput';
import SpiderSettingsRodRocDropdown from './SpiderSettingsRodRocDropdown';
import SpiderSettingsReportIntervalDropdown, {
  HD_FLIGHT_TRACKING
} from './SpiderSettingsReportIntervalDropdown';
import {
  getSpiderSettingsError,
  saveSpiderSettingsError
} from '../../../helpers/globalNotifications';
import EditDisabled from './EditDisabled';
import { compareSpiderSoftwareVersion } from '../../../helpers/compareVersion';

export const validateField = (unit, field) => {
  const speedField = {
    knots: {
      validationRegexPattern: '^([5-9]|[1-8][0-9]|9[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4]|0)$',
      errorMessage: 'Unsupported value. Must be a whole number between 5-254 knots.'
    },
    kmh: {
      validationRegexPattern: '^([1-8][0-9]|9[0-9]|[1-3][0-9]{2}|4[0-6][0-9]|0|470)$',
      errorMessage: 'Unsupported value. Must be a whole number between 10-470 km/h.'
    },
    mih: {
      validationRegexPattern: '^([6-9]|[1-8][0-9]|9[0-9]|1[0-9]{2}|2[0-8][0-9]|0|290)$',
      errorMessage: 'Unsupported value. Must be a whole number between 6-290 mi/h.'
    },
    ms: {
      validationRegexPattern: '^([3-9]|[1-8][0-9]|9[0-9]|1[0-2][0-9]|0|130)$',
      errorMessage: 'Unsupported value. Must be a whole number between 3-130 m/s.'
    }
  };

  const autowatchField = {
    knots: {
      validationRegexPattern: '^([1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|0|99[0-9])$',
      errorMessage: 'Unsupported value. Must be a whole number between 10-999 knots.'
    },
    kmh: {
      validationRegexPattern:
        '^(19|[2-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|1[0-7][0-9]{2}|18[0-4][0-9]|0|1850)$',
      errorMessage: 'Unsupported value. Must be a whole number between 19-1850 km/h.'
    },
    mih: {
      validationRegexPattern:
        '^(1[2-9]|[2-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|10[0-9]{2}|11[0-4][0-9]|0|1150)$',
      errorMessage: 'Unsupported value. Must be a whole number between 12-1150 mi/h.'
    },
    ms: {
      validationRegexPattern: '^([6-9]|[1-8][0-9]|9[0-9]|[1-4][0-9]{2}|50[0-9]|51[0-4]|0)$',
      errorMessage: 'Unsupported value. Must be a whole number between 6-514 m/s.'
    }
  };
  switch (field) {
    case 'autowatch':
      switch (unit) {
        case 'knots':
          return autowatchField.knots;
        case 'km/h':
          return autowatchField.kmh;
        case 'mi/h':
          return autowatchField.mih;
        case 'm/s':
          return autowatchField.ms;
        default:
          return autowatchField.ms;
      }
    case 'speed':
      switch (unit) {
        case 'knots':
          return speedField.knots;
        case 'km/h':
          return speedField.kmh;
        case 'mi/h':
          return speedField.mih;
        case 'm/s':
          return speedField.ms;
        default:
          return speedField.ms;
      }
    default:
      throw new Error('Unknown Field');
  }
};

const NonSpidertracksSpiderHelpMessage = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '3rem' }}>
    <Row>Reporting settings for this device are unable to be viewed or managed here.</Row>
    <Row>
      Refer to the support documentation for your tracking device to learn how you can view current
      settings and modify these.
    </Row>
  </div>
);

export const SpiderSettingsForm = ({
  onClose,
  serialNumber,
  userSpeedUnit,
  userDropAndClimbRateUnit,
  form,
  aircraftRegistration,
  aircraftType,
  saveButtonDisabled,
  setSaveButtonDisabled
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSpiderSettingsLoading, setIsSpiderSettingsLoading] = useState(true);
  const [spider, setSpider] = useState({
    spiderType: SpiderType.SX,
    spiderSoftwareVersion: '3.0.0.7',
    spiderUpdatingType: SpiderUpdatingType.TIME
  });

  const MIN_LEGACY_SPIDER_SOFTWARE_VERSION_SUPPORTING_MULTIPLE_SPEED_TRIGGERS = '2.8.4.1';
  const SPIDER_TYPES_SUPPORTING_ALTITUDE_TRIGGERS = [
    SpiderType.SX,
    SpiderType.S8,
    SpiderType.S7,
    SpiderType.S6
  ];
  const SPIDER_TYPES_SUPPORTING_HEADING_CHANGE_TRIGGER = [
    SpiderType.SX,
    SpiderType.S8,
    SpiderType.S7,
    SpiderType.S6,
    SpiderType.S5,
    SpiderType.S4,
    SpiderType.S3
  ];
  const SPIDERTRACKS_SUPPORT_SPIDER_TYPES = [
    SpiderType.SX,
    SpiderType.S8,
    SpiderType.S7,
    SpiderType.S6,
    SpiderType.S5,
    SpiderType.S4,
    SpiderType.S3_LITE,
    SpiderType.S3,
    SpiderType.S2,
    SpiderType.S1
  ];

  const SpidertracksSDK = getInstance();

  const { getFieldValue, getFieldDecorator, setFieldsValue, getFieldsError, validateFields } = form;

  const fieldsError = getFieldsError();
  let hasErrors = false;
  if (Object.entries(fieldsError).length !== 0) {
    hasErrors = Object.values(fieldsError).some(value => value);
  }

  const isVehicleEditDisabled = aircraftType === 'OTHER';

  const disabled = saveButtonDisabled || isSaving || hasErrors || isVehicleEditDisabled;

  const speedValidation = validateField(userSpeedUnit, 'speed');
  const autowatchValidation = validateField(userSpeedUnit, 'autowatch');

  useEffect(() => {
    const fetchSpiderSettings = async serialNumber => {
      try {
        const {
          settings,
          spiderType,
          spiderSoftwareVersion,
          spiderUpdatingType
        } = await SpidertracksSDK.getSpiderSettingsService().getSpiderSettings(serialNumber);
        const {
          timeTriggerPrimaryS,
          timeTriggerSecondaryS,
          headingTriggerDegrees,
          verticalSpeedClimbEntryTriggerMpsE1,
          verticalSpeedDescentEntryTriggerMpsE1,
          horizontalSpeed_1EntryTriggerMpsE1,
          autoWatchTriggerMpsE1
        } = settings;

        const reportInterval =
          spiderType !== SpiderType.SX && spiderUpdatingType === SpiderUpdatingType.DISTANCE
            ? 'N/A'
            : timeTriggerPrimaryS / 60 === 1 && timeTriggerSecondaryS === 15
            ? HD_FLIGHT_TRACKING
            : timeTriggerPrimaryS / 60;

        // Format speed from meters/second to the user speed unit format
        const formattedSettings = {
          reportInterval,
          headingTriggerDegrees,
          descentRateThreshold: roundToTenth(
            formatSpeedFromMetersPerSeconds(
              verticalSpeedDescentEntryTriggerMpsE1,
              userDropAndClimbRateUnit
            )
          ),
          climbRateThreshold: roundToTenth(
            formatSpeedFromMetersPerSeconds(
              verticalSpeedClimbEntryTriggerMpsE1,
              userDropAndClimbRateUnit
            )
          ),
          // round the formatted speed as the input component only allows whole numbers.
          speedUpDownThreshold: Math.round(
            formatSpeedFromMetersPerSeconds(horizontalSpeed_1EntryTriggerMpsE1, userSpeedUnit)
          ),
          automatedWatchThreshold: Math.round(
            formatSpeedFromMetersPerSeconds(autoWatchTriggerMpsE1, userSpeedUnit)
          )
        };
        setSpider({ spiderType, spiderSoftwareVersion, spiderUpdatingType });
        setIsSpiderSettingsLoading(false); // shoulde render fields before setting values
        setFieldsValue({ ...formattedSettings });
      } catch (e) {
        getSpiderSettingsError();
        console.error(e);
      }
    };

    fetchSpiderSettings(serialNumber);
  }, []);

  const saveSpiderSettings = async e => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        const formattedSettings = {
          settings: {
            timeTriggerPrimaryS:
              values.reportInterval === 'N/A'
                ? 0
                : values.reportInterval === HD_FLIGHT_TRACKING
                ? 60
                : values.reportInterval * 60,
            timeTriggerSecondaryS: values.reportInterval === HD_FLIGHT_TRACKING ? 15 : 0,
            headingTriggerDegrees: parseFloat(values.headingTriggerDegrees),
            verticalSpeedDescentEntryTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.descentRateThreshold, userDropAndClimbRateUnit)
            ),
            verticalSpeedClimbEntryTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.climbRateThreshold, userDropAndClimbRateUnit)
            ),
            // Format speed to meters/second from the user speed unit format, then rounds it to the tenth.
            // The use case here is updating the speed settings in a spider, where the API only accepts numbers with one place decimal,
            // which get converted later on to Integer to be updated on the spider
            horizontalSpeed_1EntryTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.speedUpDownThreshold, userSpeedUnit)
            ),
            autoWatchTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.automatedWatchThreshold, userSpeedUnit)
            )
          }
        };
        setIsSaving(true);
        try {
          await SpidertracksSDK.getSpiderSettingsService().updateSpiderSettings(
            serialNumber,
            formattedSettings
          );
        } catch (e) {
          saveSpiderSettingsError();
          console.error(e);
          setIsSaving(false);
          return;
        }
        setIsSaving(false);
        onClose();
      }
    });
  };

  const isSpidertracksSpiderType = SPIDERTRACKS_SUPPORT_SPIDER_TYPES.includes(spider.spiderType);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {isSpiderSettingsLoading ? (
        <Spin />
      ) : (
        <Form
          onSubmit={saveSpiderSettings}
          layout="vertical"
          hideRequiredMark={true}
          style={{ width: '308px', margin: '30px 0 0 32px' }}
        >
          <p style={{ fontSize: '1.1em', marginBottom: '30px' }}>
            {aircraftRegistration ? (
              <span style={{ fontWeight: 'bold' }}>{aircraftRegistration}</span>
            ) : (
              `No Aircraft assigned`
            )}
          </p>
          {isSpidertracksSpiderType ? (
            <>
              <SpiderSettingsReportIntervalDropdown
                name="reportInterval"
                label={
                  <span>
                    Report Interval
                    <Tooltip
                      placement="right"
                      title={
                        <span>
                          Spider 8 and earlier require firmware v3 or later to support HD Flight
                          Tracking. HD track resolution is 15 seconds or better when in motion.
                        </span>
                      }
                    >
                      <ExclamationCircleFilled
                        className="ml-2"
                        style={{ color: 'rgba(0,0,0,0.25)' }}
                      />
                    </Tooltip>
                  </span>
                }
                validation={{
                  required: true,
                  errorMessage: 'Rate of Descent threshold is required'
                }}
                setFieldsValue={setFieldsValue}
                getFieldValue={getFieldValue}
                getFieldDecorator={getFieldDecorator}
                setSaveButtonDisabled={setSaveButtonDisabled}
                disabled={isVehicleEditDisabled}
                spiderType={spider.spiderType}
                spiderSoftwareVersion={spider.spiderSoftwareVersion}
                spiderUpdatingType={spider.spiderUpdatingType}
              />
              {SPIDER_TYPES_SUPPORTING_HEADING_CHANGE_TRIGGER.includes(spider.spiderType) && (
                <SpiderSettingsInput
                  name="headingTriggerDegrees"
                  label="Heading Change"
                  unit="degrees"
                  setFieldsValue={setFieldsValue}
                  getFieldValue={getFieldValue}
                  getFieldDecorator={getFieldDecorator}
                  validation={{
                    validationRegexPattern: '^([3-8][0-9]|0|90)$',
                    errorMessage: 'Unsupported value. Must be a whole number between 30-90 degrees.'
                  }}
                  setSaveButtonDisabled={setSaveButtonDisabled}
                  disabled={isVehicleEditDisabled}
                />
              )}
              {SPIDER_TYPES_SUPPORTING_ALTITUDE_TRIGGERS.includes(spider.spiderType) && (
                <SpiderSettingsRodRocDropdown
                  name="descentRateThreshold"
                  label="Rate of descent (ROD) threshold"
                  unit={userDropAndClimbRateUnit}
                  validation={{
                    required: true,
                    errorMessage: 'Rate of Descent threshold is required'
                  }}
                  setFieldsValue={setFieldsValue}
                  getFieldValue={getFieldValue}
                  getFieldDecorator={getFieldDecorator}
                  setSaveButtonDisabled={setSaveButtonDisabled}
                  disabled={isVehicleEditDisabled}
                />
              )}
              {SPIDER_TYPES_SUPPORTING_ALTITUDE_TRIGGERS.includes(spider.spiderType) && (
                <SpiderSettingsRodRocDropdown
                  name="climbRateThreshold"
                  label="Rate of climb (ROC) threshold"
                  unit={userDropAndClimbRateUnit}
                  setFieldsValue={setFieldsValue}
                  getFieldValue={getFieldValue}
                  getFieldDecorator={getFieldDecorator}
                  validation={{
                    required: true,
                    errorMessage: 'Rate of Climb threshold is required'
                  }}
                  setSaveButtonDisabled={setSaveButtonDisabled}
                  disabled={isVehicleEditDisabled}
                />
              )}
              {(spider.spiderType === SpiderType.SX ||
                compareSpiderSoftwareVersion(
                  spider.spiderSoftwareVersion,
                  MIN_LEGACY_SPIDER_SOFTWARE_VERSION_SUPPORTING_MULTIPLE_SPEED_TRIGGERS
                ) !== -1) && (
                <SpiderSettingsInput
                  name="speedUpDownThreshold"
                  label="Speed threshold"
                  unit={userSpeedUnit}
                  setFieldsValue={setFieldsValue}
                  getFieldValue={getFieldValue}
                  getFieldDecorator={getFieldDecorator}
                  validation={speedValidation}
                  setSaveButtonDisabled={setSaveButtonDisabled}
                  disabled={isVehicleEditDisabled}
                />
              )}
              {
                <SpiderSettingsInput
                  name="automatedWatchThreshold"
                  label="Automated Watch threshold"
                  unit={userSpeedUnit}
                  setFieldsValue={setFieldsValue}
                  getFieldValue={getFieldValue}
                  getFieldDecorator={getFieldDecorator}
                  validation={autowatchValidation}
                  setSaveButtonDisabled={setSaveButtonDisabled}
                  disabled={isVehicleEditDisabled}
                />
              }

              {spider.spiderType !== SpiderType.SX && (
                <Form.Item>
                  <Row>
                    <Alert
                      message="For legacy settings no longer shown, please contact Support for assistance."
                      type="info"
                      showIcon
                    />
                  </Row>
                </Form.Item>
              )}
              <EditDisabled
                show={isVehicleEditDisabled}
                message="Please contact customer support if reporting settings for the spider assigned to this ‘Aircraft’ needs to be updated."
              />
            </>
          ) : (
            <NonSpidertracksSpiderHelpMessage />
          )}
          <Form.Item style={{ textAlign: 'center' }} className="mt-3">
            {isSpidertracksSpiderType ? (
              <Row>
                <Button
                  className="mx-2 px-3"
                  type="secondary"
                  size="large"
                  onClick={onClose}
                  style={{ width: '85px' }}
                >
                  Cancel
                </Button>
                <Button
                  className="mx-2 px-4"
                  disabled={disabled}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: '85px' }}
                >
                  {isSaving ? (
                    <span className="px-2">
                      <Spin size="small" />
                    </span>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Row>
            ) : (
              <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="mx-2 px-4"
                  type="primary"
                  size="large"
                  onClick={onClose}
                  style={{
                    width: '85px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  Dismiss
                </Button>
              </Row>
            )}
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

SpiderSettingsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  serialNumber: PropTypes.string.isRequired,
  userSpeedUnit: PropTypes.string.isRequired,
  userDropAndClimbRateUnit: PropTypes.string.isRequired,
  form: PropTypes.object,
  refreshDeviceTable: PropTypes.func,
  aircraftRegistration: PropTypes.string,
  aircraftType: PropTypes.string,
  saveButtonDisabled: PropTypes.bool.isRequired,
  setSaveButtonDisabled: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  userSpeedUnit: getSpeedUnit(state),
  userDropAndClimbRateUnit: getAltitudeUnit(state) === UserAltitudeUnit.FEET ? 'ft/min' : 'm/s'
});

export const WrappedSpiderSettingsForm = Form.create()(SpiderSettingsForm);

export default connect(mapStateToProps)(WrappedSpiderSettingsForm);
