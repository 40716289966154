import React from 'react';
import { Form, InputNumber, Row, Tooltip } from 'antd';
import { getThresholdInputLabel, getValidationRules } from './utils';
import { SeverityThresholdLevel } from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';

export interface Parameter {
  name: string;
  displayName: string;
  op: string;
  unit: string;
  displayUnit: string;
  minDisplayValue: number;
  maxDisplayValue: number;
  type: string;
  displayValue:
    | number
    | {
        low: number;
        medium: number;
        high: number;
      };
}

interface ComponentProps {
  parameter: Parameter;
  showSeverity: boolean;
  severityThresholdsEnabled: boolean;
  getFieldDecorator: Function;
  setFieldsValue: Function;
}

export const ParameterInput = ({
  parameter,
  showSeverity,
  severityThresholdsEnabled,
  getFieldDecorator,
  setFieldsValue
}: ComponentProps) => {
  const validationRules = getValidationRules(parameter);

  const getInitialValue = (parameter: Parameter, severityThreshold?: 'low' | 'medium' | 'high') => {
    if (parameter.displayValue === undefined) {
      return undefined;
    }

    if (typeof parameter.displayValue === 'number') {
      return parameter.displayValue;
    }

    if (severityThreshold) {
      return parameter.displayValue[severityThreshold];
    }

    return undefined;
  };

  const getFormItemForParameter = (
    parameter: Parameter,
    severityThresholdsEnabled: boolean,
    severityThreshold?: SeverityThresholdLevel
  ) => {
    let fieldName = `displayThresholds.${parameter.name}`;
    if (severityThresholdsEnabled) {
      fieldName = severityThreshold
        ? `severityDisplayThresholds.${parameter.name}.${severityThreshold}`
        : `severityDisplayThresholds.${parameter.name}`;
    }

    const formItemClassName = 'mt3 form-item-short-1';
    const inputNumberWidth = '80px';
    const inputNumberClassName = severityThreshold ? `severity-${severityThreshold}` : undefined;
    const inputNumberTestId = severityThreshold
      ? `${parameter.name}-${severityThreshold}`
      : parameter.name;
    const initialValue = getInitialValue(parameter, severityThreshold);
    let placeholder = '';

    const label = (
      <Tooltip title={severityThreshold} trigger={'hover'}>
        {getThresholdInputLabel(parameter)}
      </Tooltip>
    );

    return (
      <Row>
        <Form.Item
          label={label}
          style={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'row'
          }}
          className={formItemClassName}
        >
          {getFieldDecorator(fieldName, {
            initialValue,
            validateFirst: true,
            rules: [
              {
                message: 'Please enter a value',
                required: true
              },
              ...validationRules
            ]
          })(
            <InputNumber
              data-testid={inputNumberTestId}
              className={inputNumberClassName}
              onChange={val => setFieldsValue({ [fieldName]: val })}
              placeholder={placeholder}
              style={{ width: inputNumberWidth }}
            />
          )}
          <span style={{ paddingTop: 15, paddingLeft: 5 }}>{parameter.displayUnit}</span>
        </Form.Item>
      </Row>
    );
  };

  return (
    <>
      {showSeverity ? (
        <>
          {getFormItemForParameter(parameter, true, 'low')}
          {getFormItemForParameter(parameter, true, 'medium')}
          {getFormItemForParameter(parameter, true, 'high')}
        </>
      ) : (
        <>{getFormItemForParameter(parameter, severityThresholdsEnabled)}</>
      )}
    </>
  );
};
