import { Position } from '../../../common/api/spidertracks-sdk/types/FlightData';
import { FlightDataSample } from '../../../types/FlightData';

/** Assumes that trackPoints and gpsPositions are both sorted by timestamp */

/* Also just to be annoying, trackPoint timestamps are in milliseconds, while gpsPosition timestamps are in seconds */
export function lockTo3DFRTrack(
  trackPoints: Position[],
  flightData: FlightDataSample[]
): Position[] {
  const lockedTrack: Position[] = [];
  let flightDataIndex = 0;
  for (const point of trackPoints) {
    while (
      flightDataIndex < flightData.length &&
      flightData[flightDataIndex].sampleTimeEpochMillis < point.timestamp
    ) {
      flightDataIndex++;
    }
    if (
      flightDataIndex < flightData.length &&
      flightData[flightDataIndex].sampleTimeEpochMillis === point.timestamp
    ) {
      lockedTrack.push({
        ...point,
        latitude: flightData[flightDataIndex].latitude,
        longitude: flightData[flightDataIndex].longitude,
        altitude: flightData[flightDataIndex].amslMetres
      });
    } else {
      lockedTrack.push(point);
    }
  }
  return lockedTrack;
}
