import React, { useEffect, useState } from 'react';
import { Form, Drawer } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import './styles.scss';
import { OrganisationMember } from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import { PersonAssociatedTag } from '../../../../common/api/spidertracks-sdk/types/PersonAssociatedTag';
import {
  PrivateAircraftData,
  PublicAircraftData
} from '../../../../common/api/spidertracks-sdk/types/AircraftData';
import {
  getObfuscatedMobilePhoneNumber,
  getOrganisationMemberDisplayName
} from './OrganisationMembersContainer';
import { OrganisationMemberForm, OrganisationMemberFormData } from './OrganisationMemberForm';

interface ComponentProps extends FormComponentProps {
  organisationMember: OrganisationMember;
  allTags: PersonAssociatedTag[];
  personTag: PersonAssociatedTag | undefined;
  organisationAircraft: (PublicAircraftData | PrivateAircraftData)[];
  onClose: () => void;
  onSave: (success: boolean) => void;
  removeOrganisationMembers: (memberIds: string[]) => void;
  isUserLevelSpidertxtEnabled: boolean;
  isTagsAdminFlagEnabled: boolean;
}

const OrganisationMemberDrawer = ({
  organisationMember,
  allTags,
  personTag,
  organisationAircraft,
  onSave,
  onClose,
  removeOrganisationMembers,
  isUserLevelSpidertxtEnabled,
  isTagsAdminFlagEnabled,
  form
}: ComponentProps) => {
  const [formData, setFormData] = useState<OrganisationMemberFormData | null>(null);

  useEffect(() => {
    const getSelectedAircraft = () => {
      if (!organisationMember || !organisationAircraft) {
        return [];
      }

      if (organisationMember.defaultAircraftAccess) {
        return organisationAircraft.map(aircraft => aircraft.id);
      }

      return organisationMember.aircraft.map(aircraft => (aircraft === '*' ? '*' : aircraft.id));
    };

    if (organisationMember) {
      setFormData({
        memberId: organisationMember.id,
        organisationId: organisationMember.organisationId,
        role: organisationMember.role,
        allTags: allTags,
        personTag: personTag ? { id: personTag.id, macAddress: personTag.macAddress } : undefined,
        organisationAircraft: organisationAircraft
          .map(aircraft => ({
            label: aircraft.registration,
            value: aircraft.id,
            disabled: false
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        selectedAircraft: getSelectedAircraft(),
        defaultAircraftAccess: organisationMember.defaultAircraftAccess,
        spiderTxtStatus: organisationMember.spiderTxtEnabled ? 'Available' : 'Unavailable',
        isOwner: organisationMember.role === 'OWNER'
      });
    }
  }, [organisationMember, personTag, allTags, organisationAircraft]);

  const getTableRow = (name: string, value: string) => (
    <tr>
      <td
        style={{
          fontWeight: 'bold',
          paddingRight: '1em',
          paddingBottom: '1em',
          paddingTop: '1em'
        }}
      >
        {name}
      </td>
      <td id="member-drawer-text-content">{value}</td>
    </tr>
  );

  return (
    <Drawer
      data-testid={'organisation-member-edit-drawer'}
      title="Edit"
      placement="right"
      onClose={onClose}
      visible={true}
      width={400}
    >
      <div style={{ marginLeft: '3em' }}>
        <table>
          <tbody
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              borderSpacing: 0
            }}
          >
            {getTableRow('Name:', getOrganisationMemberDisplayName(organisationMember))}
            {getTableRow('Email:', organisationMember.email)}
            {getTableRow('Mobile:', getObfuscatedMobilePhoneNumber(organisationMember))}
          </tbody>
        </table>

        <div style={{ paddingTop: '2em' }}>
          <OrganisationMemberForm
            initialData={formData}
            onClose={onClose}
            onSave={onSave}
            removeOrganisationMembers={removeOrganisationMembers}
            isUserLevelSpidertxtEnabled={isUserLevelSpidertxtEnabled}
            isTagsAdminFlagEnabled={isTagsAdminFlagEnabled}
            form={form}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Form.create<ComponentProps>()(OrganisationMemberDrawer);
